
import { Link } from "react-router-dom"

export default function Card ({img,total,name,deals}){
    return(
        <div className=" bg-gray-50 rounded-xl duration-300 hover:scale-95 shadow-lg hover:shadow-xl">
        <Link>
            <img src={img}
                    alt="Product" className=" h-[23rem] w-full object-cover rounded-t-xl object-[50%_10%]" />
            <div className="px-4 py-3">
                <p className="text-lg font-bold text-black truncate block capitalize">{name}</p>
                <div className="">
                    <p className="text-lg font-semibold text-black text-[20px] cursor-auto my-3">Total : {total}$ </p>
                    <p className="text-lg font-semibold text-black cursor-auto my-3 text-[20px]">Closed Deals : {deals} </p>
                </div>
            </div>
        </Link>
    </div>
    )
}