import { useCallback, useEffect, useState } from "react";
import WhiteTr from "../components/whitetr";
import axios from "axios";
import Export from "../components/export";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import { deletRow, displayedCountAtom, urlOfHost } from "../components/atoms";  
import ExportAllData from "../components/exportAllData";
import DefaultContactOrdering from "../components/defaultContactOrdering";
import UpContactOrdering from "../components/upContactOrdering";
import DownContactOrdering from "../components/downContactOrdering";
import StatusFilter from "../components/statusFilterOfContact";
import RoleFilter from "../components/rolesselect";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";

export default function Users() {
    const [linkOfHost,] = useRecoilState(urlOfHost);

    const [labelOfOptionOneToStatusFilter,setLabelOfOptionOneToStatusFilter] = useState('Active');
    const [valueOfOptionOneToStatusFilter,setvalueOfOptionOneToStatusFilter] = useState(true);

    const [labelOfOptionTwoToStatusFilter,setLabelOfOptionTwoToStatusFilter] = useState('InActive');
    const [valueOfOptionTwoToStatusFilter,setValueOfOptionTwoToStatusFilter] = useState(false);

    const [apply,setApply] = useState(false);
    const [itemsNumber, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);


    const [ser, setSer] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [displayedCount, setDisplayedCount] = useRecoilState(displayedCountAtom);
    const [rowDeleted,setRowDeleted] = useRecoilState(deletRow);

    const [orderedId, setOrdredId] = useState('');
    const [ordredFullName, setOrdredFullName] = useState('');
    const [ordredUserName, setOrdredUsername] = useState('');
    const [orderedEmail, setOrdredEmail] = useState('');


    const [upEnglishName, setUpEngllishName] = useState(false);
    const [downEnglishName, setDownEnglishName] = useState(false);
    const [defaultEnglishName, setDefaultEnglishName] = useState(true);


    const [myupEmailOrdring, setMyUpEmailOrdring] = useState(false);
    const [mydownEmailOrdring, setMydownEmailOrdring] = useState(false);
    const [mydefaultEmailOrdring, setMyDefaultEmailOrdring] = useState(true);


    const [myUpUsernameOrdring, setMyUpUsernameordering] = useState(false);
    const [myDownUsernameOrdring, setMyDownUserNameOrdring] = useState(false);
    const [mydDefaultUsernameOrdering, setMyDefaultUsernameOrdring] = useState(true);

    const [down, setDownOrdeing] = useState(false);
    const [up, setUpOrdring] = useState(false);
    const [defaultO, setDefaultOrdring] = useState(true);

    const [status, setStatus] = useState('');
    const [labelOfStatusFilter,setLabelOfStatusFilter] = useState('Status');
    const [clearFiltersOfUsers,setClearFiltersOfUsers] = useState(false);

    const [valueOfRoleFilter, setValueOfRoleFilter] = useState('');
    const [labelOfRoleFilter, setLabelOfRoleFilter] = useState('Role');


    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);  
    const url = `${linkOfHost}/auth/users/`;
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const getUsers = async (reset = false) => {
        if (loading) return;
    
        setLoading(true);
        try {
            const { data } = await axios.get(url, {
                params: {
                    offset: reset ? 0 : offset,
                    limit: 20,
                    role: valueOfRoleFilter,
                    is_active: status,
                    search:ser,
                    ordering: `${orderedId},${ordredFullName},${ordredUserName},${orderedEmail}`,
                },
            });
            if (reset) {
                setUsers(data.results);
                setOffset(20);
            } else {
                setUsers(prevUsers => [...prevUsers, ...data.results]);
                setOffset(prevOffset => prevOffset + 20);
            }
            setCount(data.count);
            setHasMore(data.results.length > 0);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            setIsLoading(false);
        }
    };
    
    const handleScroll = useCallback(() => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && hasMore && !loading) {
            getUsers(); 
        }
    }, [hasMore, loading]);
    
    useEffect(() => {
        getUsers(true); 
    }, [apply]);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);
    const handleSelectAll = (currentPage) => {
        const currentUsers = users;  
        const isAllSelected = currentUsers.every(user => selectedRows[currentPage]?.some(row => row.id === user.id));
        if (isAllSelected) {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: []
            }));
        } else {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: currentUsers
            }));
        }
    };
    

    const handleSelectRow = (user, currentPage) => {
        const isSelected = selectedRows[currentPage]?.some(row => row.id === user.id);
        if (isSelected) {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: prevSelected[currentPage]?.filter(row => row.id !== user.id) || []
            }));
        } else {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: [...(prevSelected[currentPage] || []), user]
            }));
        }
    };
    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const selectedUsers = Object.values(selectedRows).flat();
            console.log(selectedUsers);
                if (selectedUsers.length === 0) return;
                    await Promise.all(
                    selectedUsers.map(phase =>
                        axios.delete(`${linkOfHost}/auth/users/${phase.id}/`).then(res=>{console.log(res)})
                    )
                );
                setIsLoading(false)
                setSelectedRows({});
                Swal.fire({
                    title:"Users deleted successfully",
                    icon:"success"
                    })
                setRowDeleted(!rowDeleted);
        } catch (error) {
            setIsLoading(false)
            console.error("Error deleting users:", error);
        }
    };
    useEffect(() => {
        if(clearFiltersOfUsers===false){
            getUsers(true);
        }
    }, [clearFiltersOfUsers]);
    
    useEffect(() => {
        setDisplayedCount(0);
        if (itemsNumber > 0) {
          const duration = 1500; 
          const stepTime = Math.abs(Math.floor(duration / itemsNumber)); 
          const counter = setInterval(() => {
            setDisplayedCount((prevCount) => {
              if (prevCount < itemsNumber) {
                return prevCount + 1; 
              } else {
                clearInterval(counter); 
                return prevCount;
              }
            });
          }, stepTime);
    
          return () => clearInterval(counter); 
        }
      }, [itemsNumber]);
    
    return (
        <div className="mt-5 mr-3 ml-6 sm:ml-0">
            {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <div className="mb-3 flex flex-row justify-between ">
                <div className=" flex flex-row">
                <button className="mr-5">
                    <Link to={"/users/adduser"} className="px-4 text-white font-medium rounded-lg py-2 bg-[#37c] hover:bg-[#5491db]">
                        Add user
                    </Link>
                </button>
                <ExportAllData url={`${linkOfHost}/auth/users/all-data/`} data='users' setState={setIsLoading} />
                </div>
                {
                    Object.values(selectedRows).some(rows => rows.length > 0) ? (
                        <div className="flex flex-row gap-x-3">
                            <button onClick={handleDelete}className="bg-red-500 hover:bg-red-600 px-2 py-1 text-white rounded-lg">Delete Selected</button>
                            <Export pageName={'users'} setIsLoading={setIsLoading} selectedRows={selectedRows} />
                        </div>
                    ) : null
                }

                </div>
            <hr className="mt-2 mb-3" />
            <div className="flex flex-row items-center justify-between text-lg text-gray-900  bg-[#4ab6c5dd] rounded-md h-10 w-full px-6 pr-[0.15rem]">
                <div className="flex flex-row ">
                    <RoleFilter setValueOfRoleFilter={setValueOfRoleFilter} setClearFilters={setClearFiltersOfUsers} setLabelOfRoleFilter={setLabelOfRoleFilter} labelOfRoleFilter={labelOfRoleFilter} />
                    <StatusFilter labelOfoptionOne={labelOfOptionOneToStatusFilter} labelOfoptionTwo={labelOfOptionTwoToStatusFilter} valueOfOptionOne={valueOfOptionOneToStatusFilter} valueOfOptionTwo={valueOfOptionTwoToStatusFilter} page={'users'} setValueOfStatusFilter={setStatus} setClearFilter={setClearFiltersOfUsers} setLabelOfStatusFilter={setLabelOfStatusFilter} labelOfStatusFilter={labelOfStatusFilter} />
                </div>
                <div className=" flex justify-self-end">
                    <Tooltip
                    className="z-10"
                    anchorId={`tooltip`}       
                    render={() => (
                    <div>
                        <p className="text-left text-[16px]">filters</p>
                        <p>{ser!==''?(`search : ${ser}`):('')}</p>
                        <p>{status!==''?(`status : ${status===true?('Active'):('InActive')}`):(``)}</p>
                        <p>{valueOfRoleFilter!==''?(`Role : ${valueOfRoleFilter=='1'?('Customer Services'):(valueOfRoleFilter=='3'?('Sales Manger'):(valueOfRoleFilter=='2'?('Sales Rep'):('Admin')))}`):(``)}</p>
                    </div>
                    )}
                    />
                    {
                        clearFiltersOfUsers?(<button onClick={(e)=>{
                            setIsLoading(true);
                            document.getElementById('ser').value='';
                            setSer('');
                            setClearFiltersOfUsers(false);
                            setLabelOfRoleFilter(labelOfRoleFilter);
                            setValueOfRoleFilter('');
                            setLabelOfStatusFilter('Status');
                            setStatus('');
                        }} className=" font-sm text-white mx-3">Clear Filters
                        </button>):('')
                    }  
                        <div className="relative flex items-center w-80">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>    
                            <input
                                id="ser"
                                onChange={(e) => {setSer(e.target.value)
                                    setClearFiltersOfUsers(true)
                                }}
                                type="search"
                                className="block focus:outline-none w-full p-2 ps-10 pe-20 text-sm text-gray-900 rounded-lg bg-gray-50"
                                placeholder="Search"
                                required
                            />
                            <button
                                id="tooltip"
                                onClick={() => {setApply(!apply)
                                    setIsLoading(true)
                                }}
                                className="absolute inset-y-0 my-[0.8px] mr-[0.5px] end-0 px-4 text-white text-[16px] rounded-lg bg-blue-400 hover:bg-blue-500"
                            >
                                Apply
                            </button>
                        </div>
                </div>
            </div>
            <div className="flex flex-col mt-3">
                <div className="overflow-x-auto min-w-[1030px] pb-4">
                    <div className="min-w-full inline-block align-middle">
                        <div className="overflow-hidden border-none rounded-lg">
                            <table className="table-auto  rounded-xl">
                                <thead className="w-full">
                                    <tr className="bg-blue-100">
                                        <th className="">
                                            <div className="flex items-center px-5 ">
                                                <input
                                                    onChange={() => handleSelectAll(currentPage)} 
                                                    checked={users.length > 0 && users.every(user => selectedRows[currentPage]?.some(row => row.id === user.id))} 
                                                    type="checkbox" value="" className="w-5 h-5 appearance-none border border-gray-300 rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-center checked:border-indigo-500 checked:bg-indigo-100" />
                                            </div>
                                        </th>
                                        <th scope="col" className="w-[11%] text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900  capitalize"> User ID {
                                        defaultO===true?(<DefaultContactOrdering left={'left-[15px]'} top={'top-[22px]'} value={'id'} setValue={setOrdredId} setDefaultOrdering={setDefaultOrdring} setDownOrdering={setDownOrdeing} setUpOrdering={setUpOrdring} />):
                                        (up===true?(<UpContactOrdering left={'left-[56px]'} top={'top-[28px]'} value={'-id'} setValue={setOrdredId} setDefaultOrdering={setDefaultOrdring} setDownOrdering={setDownOrdeing} setUpOrdering={setUpOrdring} />):
                                        (<DownContactOrdering left={'left-[45px]'} top={'top-[28px]'} value={'id'} setValue={setOrdredId} setDefaultOrdering={setDefaultOrdring} setDownOrdering={setDownOrdeing} setUpOrdering={setUpOrdring} />))
                                        }</th>
                                        <th scope="col" className="w-2/12 text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Full Name {
                                        defaultEnglishName===true?(<DefaultContactOrdering left={'left-[15px]'} top={'top-[22px]'} value={'full_name_en'} setValue={setOrdredFullName} setDefaultOrdering={setDefaultEnglishName} setDownOrdering={setDownEnglishName} setUpOrdering={setUpEngllishName} />):
                                        (upEnglishName===true?(<UpContactOrdering left={'left-[72px]'} top={'top-[28px]'} value={'-full_name_en'} setValue={setOrdredFullName} setDefaultOrdering={setDefaultEnglishName} setDownOrdering={setDownEnglishName} setUpOrdering={setUpEngllishName} />):
                                        (<DownContactOrdering left={'left-[60px]'} top={'top-[28px]'} value={'full_name_en'} setValue={setOrdredFullName} setDefaultOrdering={setDefaultEnglishName} setDownOrdering={setDownEnglishName} setUpOrdering={setUpEngllishName} />))
                                        }</th>
                                        <th scope="col" className="w-[15%] text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Username {
                                        mydDefaultUsernameOrdering===true?(<DefaultContactOrdering left={'left-[15px]'} top={'top-[22px]'} value={'username'} setValue={setOrdredUsername} setDefaultOrdering={setMyDefaultUsernameOrdring} setDownOrdering={setMyDownUserNameOrdring}  setUpOrdering={setMyUpUsernameordering} />):
                                        (myUpUsernameOrdring===true?(<UpContactOrdering left={'left-[72px]'} top={'top-[28px]'} value={'-username'} setValue={setOrdredUsername} setDefaultOrdering={setMyDefaultUsernameOrdring} setDownOrdering={setMyDownUserNameOrdring}  setUpOrdering={setMyUpUsernameordering} />):
                                        (<DownContactOrdering left={'left-[60px]'} top={'top-[28px]'} value={'username'} setValue={setOrdredUsername} setDefaultOrdering={setMyDefaultUsernameOrdring} setDownOrdering={setMyDownUserNameOrdring}  setUpOrdering={setMyUpUsernameordering} />))
                                        }</th>
                                        <th scope="col" className="w-[23%] text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Email{
                                        mydefaultEmailOrdring===true?(<DefaultContactOrdering left={'left-[15px]'} top={'top-[22px]'} value={'email'} setValue={setOrdredEmail} setDefaultOrdering={setMyDefaultEmailOrdring} setDownOrdering={setMydownEmailOrdring} setUpOrdering={setMyUpEmailOrdring} />):
                                        (myupEmailOrdring===true?(<UpContactOrdering left={'left-[44px]'} top={'top-[28px]'} value={'-email'} setValue={setOrdredEmail} setDefaultOrdering={setMyDefaultEmailOrdring} setDownOrdering={setMydownEmailOrdring} setUpOrdering={setMyUpEmailOrdring} />):
                                        (<DownContactOrdering left={'left-[33px]'} top={'top-[28px]'} value={'email'} setValue={setOrdredEmail} setDefaultOrdering={setMyDefaultEmailOrdring} setDownOrdering={setMydownEmailOrdring} setUpOrdering={setMyUpEmailOrdring} />))
                                        }</th>
                                        <th scope="col" className="w-1/12 text-left whitespace-nowrap text-sm  leading-6 font-semibold text-gray-900 capitalize"> Role </th>
                                        <th scope="col" className="w-1.5/12 text-left whitespace-nowrap text-sm leading-6 font-semibold text-gray-900 capitalize "> Status </th>
                                        <th scope="col" className=" bg-white w-1/12 text-left "><p className="inline-block rounded-md text-center text-[17px] ml-2 font-medium  text-white bg-[#4ab6c5dd] px-3 py-5 w-full">{displayedCount} Items</p>  </th> 
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300 w-full">
                                    {
                                        users.map((user, i) => {
                                            let bg = '';
                                            if(i%2===0){
                                                bg='bg-white'
                                            }else{
                                                bg='bg-gray-100'
                                            }
                                            const isChecked = selectedRows[currentPage]?.some((row) => row.id === user.id);
                                            return (
                                                <WhiteTr setIsLoading={setIsLoading} bgColor={bg} key={user.id} user={user} isChecked={isChecked} handleSelectRow={() => handleSelectRow(user, currentPage)} />
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {loading && (
                                <div className="flex justify-center py-4">
                                    <ClipLoader color="blue" loading={loading} size={60} />
                                </div>
                            )}
                            {!hasMore && <div className="text-center py-4 font-bold"> There are no more users.</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}