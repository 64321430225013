import { useRecoilState } from "recoil"
import { countries } from "./countries"
import { country} from "./atoms"

export default function SelectCountry (){
    const [mycountry,setMyCountry] = useRecoilState(country);
    return(
        <div className="w-[46%]">
            <label className="block mb-2 font-medium">Select Country</label>
            <select onChange={(e)=>{
                setMyCountry(e.target.value);
            }} className=" w-full bg-blue-500 text-white py-2 px-2 rounded-lg">
                {countries.map((country,i)=>{
                        return(
                            <option value={country.code} key={country.name} className=" text-black bg-gray-200">{country.name}</option>
                        )
                })}
            </select>
        </div>
    )
}