
export default function DefaultContactOrdering({setUpOrdering,setDownOrdering,setDefaultOrdering,setValue,value}){
        return(
            <button onClick={(e)=>{
                setValue(value);
                setDownOrdering(false);
                setUpOrdering(true);
                setDefaultOrdering(false);
            }} className=" inline-block absolute  top-[22px]">
                <svg
                className="w-2 h-2.5 ms-2 rotate-180 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
            >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
            />
            </svg>
                <svg
                className="w-2 h-2.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
            >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
            />
            </svg>
            </button>
        )
}