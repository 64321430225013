import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import SignUp from "./pages/sinup";
import Forgetpassword from "./pages/forgetpassword";
import ResetPassWord from "./pages/resetpassword";
import OTPVerification from "./pages/verificationcode";
import Home from "./pages/home";
import Users from "./pages/users";
import DashBoard from "./pages/dashboard";
import Detail from "./pages/userdetail";
import Teams from "./pages/teams";
import CreateTeam from "./pages/createteam";
import AddMembers from "./pages/addmembers";
import TeamDetails from "./pages/teamdetails";
import Contact from "./pages/createcontact";
import Contacts from "./pages/contacts";
import Noet from "./pages/notesOfContact";
import Reminder from "./pages/reminderOfContact";
import General from "./pages/generalOfContact";
import MainOfContact from "./pages/mainOfContact";
import CreateNote from "./pages/createNote";
import Deals from "./pages/dealsOfContact";
import CreateReminder from "./pages/createReminder";
import DetailOfReminder from "./pages/detailOfReminder";
import CreateDeal from "./pages/createDeal";
import DealsPage from "./pages/deals";
import MainOfDealDetail from "./pages/mainOfDealDetail";
import GeneralOfDeal from "./pages/generalOfDeal";
import PhasesOfDeal from "./pages/phasesOfDeal";
import NotesOfDeal from "./pages/notesOfDeal";
import ClientInfo from "./pages/clientInfo";
import Phases from "./pages/phases";
import ProtectedRoute from "./components/protectedRout";
import Note from "./components/note";
import Error404 from "./pages/Error404";
import NotAllowed from "./pages/notAllowed";
const LoadingComponent = () => <div>Loading...</div>;

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<Forgetpassword />} />
        <Route path="/restpassword" element={<ResetPassWord />} />
        <Route path="/emailverification" element={<OTPVerification />} />
        <Route
          path="/*"
          element={
              <Routes>
                <Route path="/" element={
                  <ProtectedRoute allowedRoles={[1,2,3,4]}>
                    <Home />
                  </ProtectedRoute>
                }>
                <Route
                    path="dashboard"
                    element={
                        <ProtectedRoute allowedRoles={[4]}>
                            <DashBoard />
                        </ProtectedRoute>
                      }
                  />
                <Route
                    path="users"
                    element={
                        <ProtectedRoute allowedRoles={[4]}>
                            <Users />
                        </ProtectedRoute>
                      }
                  />
                  <Route
                    path="users/adduser"
                    element={
                        <ProtectedRoute allowedRoles={[4]}>
                            <SignUp />
                        </ProtectedRoute>
                      }
                  />
                  <Route path='users/userdetail/:id' element={
                        <ProtectedRoute allowedRoles={[4]}>
                            <Detail  />
                        </ProtectedRoute>
                      } 
                  />         
                <Route
                    path="teams/addteam"
                    element={
                        <ProtectedRoute allowedRoles={[4]}>
                            <CreateTeam />
                        </ProtectedRoute>
                      }
                  />
                <Route
                    path="teams"
                    element={
                        <ProtectedRoute allowedRoles={[4,3]}>
                            <Teams />
                        </ProtectedRoute>
                      }
                  />
                <Route
                    path="teams/:teamId/addmembers"
                    element={
                        <ProtectedRoute allowedRoles={[4,3]}>
                            <AddMembers />
                        </ProtectedRoute>
                      }
                  />
                <Route
                    path="teams/teamdetails/:teamId"
                    element={
                        <ProtectedRoute allowedRoles={[4,3]}>
                            <TeamDetails />
                        </ProtectedRoute>
                      }
                  />
                <Route
                    path="contacts"
                    element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <Contacts />
                        </ProtectedRoute>
                      }
                  />
                <Route
                    path="allDeals"
                    element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <DealsPage />
                        </ProtectedRoute>
                      }
                  />
                <Route
                    path="monitoring"
                    element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <Phases />
                        </ProtectedRoute>
                      }
                  />
                <Route
                    path="contacts/contactDetail/:contactId/dealsOfContacts/dealDetail/:dealId"
                    element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <MainOfDealDetail />
                        </ProtectedRoute>
                      }
                  >
                    <Route index element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <GeneralOfDeal />
                        </ProtectedRoute>
                      } />
                    <Route path='general' element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <GeneralOfDeal />
                        </ProtectedRoute>
                      } />
                    <Route path='phases' element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <PhasesOfDeal />
                        </ProtectedRoute>
                      } />
                    <Route path='notes' element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <NotesOfDeal />
                        </ProtectedRoute>
                      } />
                    <Route path='reminders' element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <Reminder page={'deal'} />
                        </ProtectedRoute>
                      } />
                    <Route path='clientInfo' element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <ClientInfo />
                        </ProtectedRoute>
                      } />
                  </Route>
                  <Route path='contactDetail/:contactId/deals/dealDetail/:dealId/notes/addnote' element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <CreateNote page={'deal'} />
                        </ProtectedRoute>
                      } />                  
                  <Route path='contacts/addcontact' element={
                        <ProtectedRoute allowedRoles={[4,3]}>
                            <Contact  />
                        </ProtectedRoute>
                      } />                  
                  <Route path='contacts/contactDetail/:contactId' element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                            <MainOfContact  />
                        </ProtectedRoute>
                      } >
                      <Route index element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <General />
                        </ProtectedRoute>
                      } />
                      <Route path="general" element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <General />
                        </ProtectedRoute>
                      } />
                      <Route path="notes" element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <Note />
                        </ProtectedRoute>
                      } />
                      <Route path="reminders" element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <Reminder page={'contact'} />
                        </ProtectedRoute>
                      } />
                      <Route path="dealsOfContact" element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <Deals />
                        </ProtectedRoute>
                      } />
                  </Route>
                  <Route path="contacts/contactDetail/:contactId/notes/addnote" element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <CreateNote page={'contact'} />
                        </ProtectedRoute>
                      } />                  
                  <Route path="contacts/contactDetail/:contactId/deals/addDeal" element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <CreateDeal  />
                        </ProtectedRoute>
                      } />                  
                  <Route path="contacts/contactDetail/:contactId/reminders/createReminder" element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <CreateReminder  />
                        </ProtectedRoute>
                      } />                  
                  <Route path="contacts/contactDetail/:contactId/reminders/:reminderId/reminderDetail" element={
                        <ProtectedRoute allowedRoles={[4,3,2]}>
                          <DetailOfReminder  />
                        </ProtectedRoute>
                      } />
                      <Route path="404Error" element={<Error404 />}></Route>                  
                      <Route path="403Error" element={<NotAllowed />}></Route>                  
                </Route>
                <Route path="*" element={<Navigate to="/404Error" />} />
              </Routes>
          }
        />
      </Routes>
    </div>
  );
}
