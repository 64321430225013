import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { city } from './atoms';
import { citiesByCountry } from './cites';

export default function City() {
    const [myCity,setMyCity] = useRecoilState(city);
    const [selectedCountry, setSelectedCountry] = useState('SA');

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
};

    return (
    <div>
        <label className="block mb-2 font-medium">City</label>
        <div className="flex">
        <label htmlFor="countries" className="sr-only">Choose a country</label>
        <select
        id="countries"
        value={selectedCountry}
        onChange={handleCountryChange}
        className="flex-shrink-0 z-10 inline-flex py-2.5 px-4 text-sm font-medium border bg-blue-400 text-white rounded-s-lg focus:outline-none"
    >
        <option className='text-black bg-gray-200 ' value="SA">
            Saudi Arabia
        </option>
        <option className='text-black bg-gray-200 ' value="AE">
            United Arab Emirates
        </option>
      </select>

      <label htmlFor="cities" className="sr-only">Choose a city</label>
      <select onChange={(e)=>{
        setMyCity(e.target.value);
      }}
        id="cities"
        className="bg-blue-400 border border-gray-300 text-white text-sm rounded-e-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      >
        {citiesByCountry[selectedCountry].map(city => (
          <option className='text-black bg-gray-200 ' key={city.value} value={city.value}>
            {city.label}
          </option>
        ))}
      </select>
    </div>
    </div>
  );
}
