import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { resetLink, urlOfHost, verifyEmail } from '../components/atoms';
import { useNavigate } from "react-router-dom";
import { ClipLoader } from 'react-spinners';

const OTPVerification = () => {
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const [resend,setResend] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigation = useNavigate();
    const [email,setEmail] = useRecoilState (verifyEmail)
    const [link,setLink]= useRecoilState(resetLink);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const inputsRef = useRef([]);

    const handleChange = (element, index) => {
        const value = element.value;
        if (/^[a-zA-Z0-9]$/.test(value)) {
            let newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (index < inputsRef.current.length - 1) {
                inputsRef.current[index + 1].focus();
            }
        } else if (value === "") {
            let newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };
    

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            let newOtp = [...otp];
            newOtp[index] = ""; // قم بحذف المحرف في الحقل الحالي
            setOtp(newOtp);
            if (index > 0) {
                inputsRef.current[index - 1].focus(); // نقل التركيز إلى الحقل السابق
            }
        }
    };
    

    const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    if (/^[a-zA-Z0-9]{6}$/.test(text)) {
        const digits = text.split("");
        setOtp(digits);
        inputsRef.current[inputsRef.current.length - 1].focus();
    }
};
    useEffect(() => {
    inputsRef.current[0].focus();
    if(localStorage.getItem("email")){
        setEmail(localStorage.getItem("email"));
    }
    }, []);
    return (
    <div className="relative font-inter antialiased">
        {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
        <main className="relative min-h-screen bg-slate-50 overflow-hidden">
            <div className="w-full max-w-6xl mx-auto px-4 md:px-6 p-10">
                <div className="flex justify-center">
                    <div className="max-w-md mx-auto text-center border-indigo-300 border-[1px] bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
                        <header className="mb-8">
                            <h1 className="text-2xl font-bold mb-1">Email Verification</h1>
                            <p className="text-[15px] text-slate-500">Enter the 6-character verification code that was sent to your Email.</p>
                        </header>
                        <form method="POST" onSubmit={(e)=>{
                            e.preventDefault();
                            }}>
                            <div className="flex items-center justify-center gap-3" onPaste={handlePaste}>
                                {otp.map((data, index) => (
                                    <input
                                        key={index}
                                        ref={el => inputsRef.current[index] = el}
                                        type="text"
                                        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                                        maxLength="1"
                                        value={data}
                                        onChange={e => handleChange(e.target, index)}
                                        onKeyDown={e => handleKeyDown(e, index)}
                                        onFocus={e => e.target.select()}
                                    />
                                ))}
                            </div>
                                <p id="passP" className=" text-left text-[#CE4343] text-sm pl-1 mt-1">{passwordErr}</p>
                            <div className="max-w-[260px] mx-auto mt-4">
                                <button onClick={(e)=>{
                                    setIsLoading(true);
                                    axios.post(`${linkOfHost}/auth/users/verify-reset-code/`,{
                                        code:otp.join("")
                                    }).then((res)=>{
                                        console.log(res.data.reset_link);
                                        setLink(res.data.reset_link)
                                        localStorage.setItem("link",res.data.reset_link);
                                        if(res.status===200){
                                            navigation('/restpassword');
                                        }
                                    }).catch((err)=>{
                                        if(err.response){
                                            setPasswordErr(err.response.data.code[0]);
                                            setOtp(new Array(6).fill(""));
                                        }else{
                                        }
                                    }).finally(()=>{
                                        setIsLoading(false);
                                    })
                                }} type="submit"
                                    className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-blue-300 text-sm ">
                                        Verify Account
                                </button>
                            </div>
                        </form>
                        <div className="text-sm text-slate-500 mt-4">Didn't receive code? <button onClick={(e)=>{
                            setIsLoading(true);
                            axios.post(`${linkOfHost}/auth/send-reset-code/`,{
                                email:email
                            }).then(res=>{
                                if(res.status===201){
                                    setResend(res.data.message);
                                }
                            }).catch(err=>{
                                    
                            })
                            .finally(()=>{
                                setIsLoading(false);
                            })
                        }} className="font-medium text-blue-500 hover:text-blue-700" href="#0">Resend</button>
                        <p className='text-blue-500 font-medium'>{resend}</p>
                        </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default OTPVerification;
