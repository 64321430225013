export default function DownContactOrdering({setUpOrdering,setDownOrdering,setDefaultOrdering,setValue,value,left,top}){
    return(
        <button onClick={(e)=>{
            setValue(value)
            setDownOrdering(false);
            setUpOrdering(true);
            setDefaultOrdering(false);
      }} className={`inline-block absolute ${left} ${top}`}>
          <svg
        className="w-2.5 h-2.5 ms-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m1 1 4 4 4-4"
        />
      </svg>
      </button>
  )
}