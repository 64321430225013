import SelectCountry from "../components/selectcountry";
import SeclectRole from "../components/roles";
import PhoneInputField from "../components/phone";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { clearPhoneInput, country, phone, phoneErr, role, urlOfHost } from "../components/atoms";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const [isLoading, setIsLoading] = useState(false);
    const [myphone, setMyphone] = useRecoilState(phone);
    const [myRole, setMyRole] = useRecoilState(role);
    const [mycountry, setMyCountry] = useRecoilState(country);
    const [arabicName, setArabicName] = useState('');
    const [englishName, setEnglishName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [address, setAddress] = useState('');
    const [phoneError, setPhoneErr] = useRecoilState(phoneErr);
    const [arabicNameErr, setArabicNameErr] = useState('');
    const [englishNameErr, setEnglishNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [userNameErr, setUserNameErr] = useState('');
    const [addressErr, setAddressErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e, setState, setErrorState) => {
        setState(e.target.value);
        e.target.style.border = '1px solid #3b82f6'; // إرجاع لون الإطار الأزرق الأصلي
        setErrorState(''); // إزالة رسالة الخطأ إذا كانت موجودة
    };

    const handleSubmit = () => {
        if (password !== confirmPassword&&(password.length!==0)) {
            const passwordInput = document.getElementById("password");
            const confirmPasswordInput = document.getElementById("confirm");
            passwordInput.style.border = '1px solid red';
            confirmPasswordInput.style.border = '1px solid red';
            setPasswordErr("Password does not match confirmation");
        } else {
            setIsLoading(true);
            axios.post(`${linkOfHost}/auth/users/`, {
                username: userName,
                email: email,
                full_name_ar: arabicName,
                full_name_en: englishName,
                address: address,
                country: mycountry,
                phone_number: `+${myphone}`,
                role: myRole,
                password: password
            }).then((res) => {
                navigate('/users')
                Swal.fire({
                    icon:"success",
                    title:"The account has been created successfully.",
                    text:`Your Email is : ${res.data.email}`
                })
                // setMyphone("");
                // const inputs = document.querySelectorAll('input');
                // inputs.forEach(input => {
                //     input.value = '';
                // });
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).forEach(key => {
                        if (key === "username") {
                            const userNameInput = document.getElementById("user");
                            userNameInput.style.border = '1px solid red';
                            setUserNameErr(err.response.data.username[0]);
                        }
                        if (key === "email") {
                            const emailInput = document.getElementById("email");
                            emailInput.style.border = '1px solid red';
                            setEmailErr(err.response.data.email[0]);
                        }
                        if (key === "address") {
                            const addressInput = document.getElementById("address");
                            addressInput.style.border = '1px solid red';
                            setAddressErr(err.response.data.address[0]);
                        }
                        if (key === "full_name_ar") {
                            const arabicNameInput = document.getElementById("arabic");
                            arabicNameInput.style.border = '1px solid red';
                            setArabicNameErr(err.response.data.full_name_ar[0]);
                        }
                        if (key === "full_name_en") {
                            const englishNameInput = document.getElementById("english");
                            englishNameInput.style.border = '1px solid red';
                            setEnglishNameErr(err.response.data.full_name_en[0]);
                        }
                        if (key === "password") {
                            const passwordInput = document.getElementById("password");
                            passwordInput.style.border = '1px solid red';
                            setPasswordErr(err.response.data.password[0]);
                        }
                        if (key === "phone_number") {
                            setPhoneErr(err.response.data.phone_number[0]);
                        }
                    });
                } else {
                    console.log(err);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <section className="flex flex-col items-center pt-6">
            {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <div className="w-full border-indigo-300 bg-white rounded-lg shadow border-[1px] md:mt-0 lg:max-w-xl sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">Create an account</h1>
                    <form className="space-y-4 md:space-y-6" method="POST" onSubmit={(e) => {
                        e.preventDefault();
                    }}>
                        <div className="flex gap-x-6 justify-between">
                            <div className="w-[47%]">
                                <label htmlFor="arabic" className="block mb-2 text-sm font-medium">Arabic Name</label>
                                <input
                                    onChange={(e) => handleInputChange(e, setArabicName, setArabicNameErr)}
                                    type="text" name="arabicName" id="arabic"
                                    className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                    placeholder="Arabic Name" required=""
                                />
                                <p className="text-red-500 text-sm pl-1">{arabicNameErr}</p>
                            </div>
                            <div className="w-[47%]">
                                <label htmlFor="english" className="block mb-2 text-sm font-medium">English Name</label>
                                <input
                                    onChange={(e) => handleInputChange(e, setEnglishName, setEnglishNameErr)}
                                    type="text" name="englishName" id="english"
                                    className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                    placeholder="English Name" required=""
                                />
                                <p className="text-red-500 text-sm pl-1">{englishNameErr}</p>
                            </div>
                        </div>
                        <div className="flex gap-x-6 justify-between">
                            <div className="w-[46%]">
                                <label htmlFor="user" className="block mb-2 text-sm font-medium">Username</label>
                                <input
                                    onChange={(e) => handleInputChange(e, setUserName, setUserNameErr)}
                                    type="text" name="userName" id="user"
                                    className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                    placeholder="Username" required=""
                                />
                                <p className="text-red-500 text-sm pl-1">{userNameErr}</p>
                            </div>
                            <div className="w-[46%]">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium">Email</label>
                                <input
                                    onChange={(e) => handleInputChange(e, setEmail, setEmailErr)}
                                    type="email" name="Email" id="email"
                                    className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                    placeholder="Email" required=""
                                />
                                <p className="text-red-500 text-sm pl-1">{emailErr}</p>
                            </div>
                        </div>
                        <div className="flex gap-x-6 justify-between">
                            <div className="w-[46%]">
                                <label htmlFor="password" className="block mb-2 text-sm font-medium">Password</label>
                                <input
                                    onChange={(e) => handleInputChange(e, setPassword, setPasswordErr)}
                                    type="password" name="password" id="password"
                                    className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                    placeholder="••••••••" required=""
                                />
                                <p className="text-red-500 text-sm pl-1">{passwordErr}</p>
                            </div>
                            <div className="w-[46%]">
                                <label htmlFor="confirm" className="block mb-2 text-sm font-medium">Confirm Password</label>
                                <input
                                    onChange={(e) => handleInputChange(e, setConfirmPassword, () => {})}
                                    type="password" name="confirm" id="confirm"
                                    className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                    placeholder="••••••••" required=""
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="address" className="block mb-2 text-sm font-medium">Address</label>
                            <input
                                onChange={(e) => handleInputChange(e, setAddress, setAddressErr)}
                                type="text" name="address" id="address"
                                className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                placeholder="Address" required=""
                            />
                            <p className="text-red-500 text-sm pl-1">{addressErr}</p>
                        </div>
                        <div className="flex gap-x-6 justify-between">
                            <SelectCountry />
                            <SeclectRole />
                        </div>
                        <div className="mb-4">
                            <PhoneInputField />
                        </div>
                        <button onClick={handleSubmit}
                            type="submit"
                            className="w-full text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Create an account</button>
                    </form>
                </div>
            </div>
        </section>
    );
}




