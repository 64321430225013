export const citiesByCountry = {
    SA: [
        { value: 'ABHA', label: 'Abha' },
        { value: 'AL_BAHA', label: 'Al Baha' },
        { value: 'AL_HUFUF', label: 'Al Hufuf' },
        { value: 'AL_JAWF', label: 'Al Jawf' },
        { value: 'AL_QUNFUDHAH', label: 'Al Qunfudhah' },
        { value: 'ARAR', label: 'Arar' },
        { value: 'BURAYDAH', label: 'Buraydah' },
        { value: 'DAMMAM', label: 'Dammam' },
        { value: 'HAIL', label: 'Hail' },
        { value: 'JEDDAH', label: 'Jeddah' },
        { value: 'JUBAIL', label: 'Jubail' },
        { value: 'KHAMIS_MUSHAYT', label: 'Khamis Mushayt' },
        { value: 'KHOBAR', label: 'Khobar' },
        { value: 'MECCA', label: 'Mecca' },
        { value: 'MEDINA', label: 'Medina' },
        { value: 'NAJRAN', label: 'Najran' },
        { value: 'QATIF', label: 'Qatif' },
        { value: 'RIYADH', label: 'Riyadh' },
        { value: 'SAKAKA', label: 'Sakaka' },
        { value: 'TABUK', label: 'Tabuk' },
        { value: 'TAIF', label: 'Taif' },
        { value: 'YANBU', label: 'Yanbu' }
    ],
    AE: [
        { value: 'DUBAI', label: 'Dubai' },
        { value: 'ABU_DHABI', label: 'Abu Dhabi' },
        { value: 'SHARJAH', label: 'Sharjah' },
        { value: 'AJMAN', label: 'Ajman' },
        { value: 'FUJAIRAH', label: 'Fujairah' },
        { value: 'AL_AIN', label: 'Al Ain' },
        { value: 'DIBBA_AL_HISN', label: 'Dibba Al-Hisn' },
        { value: 'KALBA', label: 'Kalba' },
        { value: 'JEBEL_ALI', label: 'Jebel Ali' },
        { value: 'KHOR_FAKKAN', label: 'Khor Fakkan' },
        { value: 'RAS_AL_KHAIMAH', label: 'Ras Al-Khaimah' },
        { value: 'UMM_AL_QUWAIN', label: 'Umm Al Quwain' },
    ]
};