import React, { useState, useEffect, useRef } from 'react';

const StatusFilter = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="relative ml-5 mr-5" ref={dropdownRef}>
      <button
        id="dropdownAvatarNameButton"
        onClick={toggleDropdown}
        className="flex items-center text-sm pe-1 font-medium text-white md:me-0"
        type="button"
      >
        <span className="sr-only">Open user menu</span>
        <span className="font-bold">{props.labelOfStatusFilter}</span>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownAvatarName"
          className="absolute top-7 left-[-13px] z-10 bg-gray-50 divide-y divide-gray-100 rounded-lg shadow w-44"
        >
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
            <li onClick={(e)=>{
                  props.setClearFilter(true);
                  props.setValueOfStatusFilter(props.valueOfOptionOne);
                  props.setLabelOfStatusFilter(props.labelOfoptionOne);
                  setIsOpen(false)
            }} className=" hover:bg-gray-100 cursor-pointer">
              <button
                className="block px-4 py-2 text-gray-900"
              >
                {props.labelOfoptionOne}
              </button>
            </li>
            <li onClick={(e)=>{
                  props.setClearFilter(true);
                  props.setValueOfStatusFilter(props.valueOfOptionTwo);
                  props.setLabelOfStatusFilter(props.labelOfoptionTwo);
                setIsOpen(false)
            }} className="hover:bg-gray-100 cursor-pointer"> 
              <button
                className="block px-4 py-2 text-gray-900"
              >
                {props.labelOfoptionTwo}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default StatusFilter;
