

export default function Error404() {
    return(
        <div className="grid place-items-center h-screen">
            <div className="font-bold text-5xl flex items-center justify-center">
                <span className="text-red-400 mr-3">404</span> Not found
            </div>
        </div>
    )
}