export const citiesData = [
    { city: 'ABHA', position: [18.2167, 42.5053], clients: 0 },
    { city: 'AL_BAHA',  position: [20.0129, 41.4677], clients: 0 },
    { city: 'AL_HUFUF',  position: [25.3647, 49.5861], clients: 0 },
    { city: 'AL_JAWF', position: [29.9697, 40.2049], clients: 0 },
    { city: 'AL_QUNFUDHAH',  position: [19.1264, 41.0788], clients: 0 },
    { city: 'ARAR', position: [30.9753, 41.0381], clients: 0 },
    { city: 'BURAYDAH',  position: [26.3336, 43.9728], clients: 0 },
    { city: 'DAMMAM',  position: [26.4207, 50.0888], clients: 0 },
    { city: 'HAIL', position: [27.5219, 41.6907], clients: 0 },
    { city: 'JEDDAH',  position: [21.4858, 39.1925], clients: 0 },
    { city: 'JUBAIL', position: [27.004, 49.646], clients: 0 },
    { city: 'KHAMIS_MUSHAYT', position: [18.3064, 42.7295], clients: 0 },
    { city: 'KHOBAR',  position: [26.2794, 50.2083], clients: 0 },
    { city: 'MECCA',  position: [21.3891, 39.8579], clients: 0 },
    { city: 'MEDINA',  position: [24.5247, 39.5692], clients: 0 },
    { city: 'NAJRAN',  position: [17.4917, 44.1322], clients: 0 },
    { city: 'QATIF',  position: [26.5658, 49.998], clients: 0 },
    { city: 'RIYADH', position: [24.7136, 46.6753], clients: 0 },
    { city: 'SAKAKA',  position: [29.9697, 40.2064], clients: 0 },
    { city: 'TABUK',  position: [28.3838, 36.5825], clients: 0 },
    { city: 'TAIF',  position: [21.4373, 40.5127], clients: 0 },
    { city: 'YANBU',  position: [24.0943, 38.049], clients: 0 },
  ];
  