import { useRecoilState } from "recoil";
import { role } from "./atoms";

export default function SeclectRole (){
    const [myRole,setMyRole] = useRecoilState(role);
    return(
        <div className="w-[46%] justify-end">
            <label className="block mb-2 font-medium">Select Role</label>
            <select defaultValue={Number(1)} onChange={(e)=>{
                setMyRole(e.target.value);
            }} className=" w-full bg-blue-500 text-white py-2 px-2 rounded-lg">
                <option value={Number(1)} className=" text-black bg-gray-200 ">Customer-Servcies</option>
                <option value={Number(4)} className=" text-black bg-gray-200">Admin</option>
                <option value={Number(3)}  className=" text-black bg-gray-200 ">Sales-Manger</option>
                <option value={Number(2)} className=" text-black bg-gray-200 ">Sales-REB</option>
            </select>
        </div>
    )
}