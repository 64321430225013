import axios from "axios";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { resetLink } from "../components/atoms";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


export default function ResetPassWord (){
    const navigation = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [newPassword,setNewPassword] = useState ('')
    const [confirmPassword,setConfirmPassword] = useState ('')
    const [link,setLink]= useRecoilState(resetLink);
    const [passwordErr, setPasswordErr] = useState('');
    const [error, setError] = useState('');
    const handleSubmit = ()=>{
        if(newPassword!==confirmPassword&&(newPassword.length!==0)){
            setPasswordErr('Password does not match confirmation')
            document.getElementById("password").style.border = '1px solid red';
            document.getElementById("confirm-password").style.border = '1px solid red';
        }else{
            setIsLoading(true);
            axios.post(`${link}`,{
                new_password:newPassword
            }).then(res=>{
                if(res.status===200){
                    const inputs = document.querySelectorAll('input');
                    inputs.forEach(input => {
                        input.value = '';
                    });
                    navigation('/login');
                    Swal.fire({
                        icon:"success",
                        title:"Password has been changed."
                    })
                }
            }).catch(err=>{
                document.getElementById('confirm-password').style.border=''
                document.getElementById('password').style.border=''
                setPasswordErr('');
                Object.keys(err.response.data).forEach((key) => {
                    if (key === "new_password") {
                        setPasswordErr(err.response.data.new_password[0]);
                        document.getElementById("password").style.border = '1px solid #CE4343';
                    }
                    if (key === "error") {
                        setError(err.response.data.error[0])
                    }
                });
            }).finally(()=>{
                setIsLoading(false);
            })
        }
    }
    useEffect(()=>{
        if(localStorage.getItem("link")){
            setLink(localStorage.getItem("link"));
        }
    },[])
    return(
        <section className="">
            {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <div className="flex flex-col items-center px-6 py-8 md:h-screen bg-gray-50 lg:py-0">
                <div className="w-full border-indigo-300 mt-10 p-6 bg-white rounded-lg shadow dark:border sm:max-w-md  sm:p-8">
                    <h2 className="mb-1 text-xl font-bold leading-tigh  tracking-tight text-gray-900 md:text-2xl ">
                        Change Password
                    </h2>
                    <p id="errors" className="text-center mb-2 mt-[4px] text-[#CE4343] text-sm">{error}</p>
                    <form method="POST" onSubmit={(e)=>{
                        e.preventDefault();
                    }} className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">New Password</label>
                            <input onChange={(e)=>{
                                e.target.style.border = '1px solid #3b82f6';
                                setNewPassword(e.target.value);
                                setPasswordErr('');
                            }} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none" required="" />
                            <p id="passP" className=" text-left text-[#CE4343] text-sm pl-1">{passwordErr}</p>
                        </div>
                        <div>
                            <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 ">Confirm Password</label>
                            <input onChange={(e)=>{
                                setConfirmPassword(e.target.value);
                                e.target.style.border = '1px solid #3b82f6';
                                setPasswordErr('');
                            }} type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none" required="" />
                        </div>
                        <button onClick={handleSubmit} type="submit" className="w-full text-white bg-primary-500 hover:bg-primary-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Reset Passwod</button>
                    </form>
                </div>
            </div>
        </section>
    )
}