import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, allowedRoles }) => {
    const [authToken, setAuthToken] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
    const navigate = useNavigate();

    useEffect(() => {
        const role = sessionStorage.getItem('role');
        const token = sessionStorage.getItem('authToken');
        if (token) {
            setAuthToken(token);
            setUserRole(parseInt(role));
            axios.interceptors.request.use(
                (config) => {
                    if (!config.skipAuth) {
                        config.headers.Authorization = `Bearer ${token}`;
                    }
                    return config;
                },
                (error) => Promise.reject(error)
            );
        } else {
            navigate('/login'); 
        }
        setIsLoading(false); 
    }, [navigate]);

    if (isLoading) {
        return <div>Loading...</div>; 
    }

    if (!authToken) {
        return <Navigate to="/login" replace />;
    }

    if (!allowedRoles.includes(userRole)) {
        return <Navigate to="/403Error" replace />;
    }

    return children;
};

export default ProtectedRoute;
