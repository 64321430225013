import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { urlOfHost } from './atoms';


const BarChart = () => {
  const [linkOfHost,] = useRecoilState(urlOfHost);
  const canvasRef = useRef(null);
  const url = `${linkOfHost}/statistics/deals-statistics/`;

  // دالة لجلب البيانات من الـ API
  const getInfo = async () => {
    try {
      const response = await axios.get(url);
      const data = response.data;
      const months = data.map(item => item.month); // أسماء الأشهر
      const dealsCounts = data.map(item => item.deals_count); // عدد الصفقات

      const ctx = canvasRef.current.getContext('2d');

      const barChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: months,
          datasets: [
            {
              label: 'Deals Count', 
              data: dealsCounts,
              backgroundColor: [
                'rgba(54, 162, 235, 0.6)',  
                'rgba(255, 206, 86, 0.6)',  
                'rgba(75, 192, 192, 0.6)',  
                'rgba(153, 102, 255, 0.6)', 
                'rgba(255, 159, 64, 0.6)',  
                'rgba(199, 199, 199, 0.6)', 
                'rgba(255, 99, 132, 0.6)',
                'rgba(54, 162, 235, 0.6)',  
                'rgba(255, 206, 86, 0.6)',  
                'rgba(75, 192, 192, 0.6)',  
                'rgba(153, 102, 255, 0.6)', 
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(199, 199, 199, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,  // تأكد من أن هذا مضبوط على true
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Yearly Deals Count',
            },
          },
        },
      });

      return () => {
        barChart.destroy(); 
      };
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInfo(); 
  }, []);

  return (
    <div className=" flex justify-center items-center rounded-lg shadow-lg mt-2 ">
      <canvas ref={canvasRef} className="w-[95%] max-w-[95%] h-auto"  />
    </div>
  );
};

export default BarChart;
