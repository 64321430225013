import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { citiesData as initialCitiesData } from '../components/saudia-arabia-map-cites';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { urlOfHost } from './atoms';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const SuadiaArabiaMab = () => {
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const url =`${linkOfHost}/statistics/cities-statistics/`;
    const [citiesData, setCitiesData] = useState(initialCitiesData); 

    const getInfo = async () => {
        try {
            const response = await axios.get(url);
            const data = response.data; 
            const updatedCitiesData = citiesData.map((city) => {
                const clientInfo = data.find(item => item.city === city.city);
                return {
                    ...city,
                    clients: clientInfo ? clientInfo.clients : city.clients 
                };
            });
            setCitiesData(updatedCitiesData); 
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <div className="relative h-[450px] w-full border-2 border-gray-950 z-0">
            <MapContainer className="h-full w-full relative z-10" center={[25, 45.0792]} zoom={5} style={{ zIndex: 1 }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {citiesData.map((city, index) => (
                    <Marker 
                        key={index} 
                        position={city.position}
                        eventHandlers={{
                            mouseover: (e) => {
                                e.target.openPopup(); 
                            },
                            mouseout: (e) => {
                                e.target.closePopup(); 
                            }
                        }}
                    >
                        <Popup>
                            <div className="text-center">
                                <h3 className="font-bold text-[16px]">{city.city}</h3>
                                <p className="font-medium text-[16px]">Clients: {city.clients}</p>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};

export default SuadiaArabiaMab;
