import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Export from "../components/export";
import { ClipLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import { deletRow, displayedCountAtom, urlOfHost } from "../components/atoms";  
import ExportAllData from "../components/exportAllData";
import PaginationOfContacts from "../components/paginationofcontacts";
import DefaultContactOrdering from "../components/defaultContactOrdering";
import UpContactOrdering from "../components/upContactOrdering";
import DownContactOrdering from "../components/downContactOrdering";
import StatusFilter from "../components/statusFilterOfContact";
import Phase from "../components/phase";
import PhaseFilter from "../components/phaseFilter";
import { Tooltip } from "react-tooltip";
import MoreFiltersOfPhases from "../components/moreFiltersOfPhases";

export default function Phases() {

    const [labelOfOptionOneToStatusFilter,setLabelOfOptionOneToStatusFilter] = useState('Completed');
    const [valueOfOptionOneToStatusFilter,setValueOfOptionOneToStatusFilter] = useState('Completed');

    const [labelOfOptionTwoToStatusFilter,setLabelOfOptionTwoToStatusFilter] = useState('Ongoing');
    const [valueOfOptionTwoToStatusFilter,setValueOfOptionTwoToStatusFilter] = useState('Ongoing');


    const [apply,setApply] = useState(false);
    const [itemsNumber, setCount] = useState(0);
    const [myOffSet, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);


    const [ser, setSer] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [displayedCount, setDisplayedCount] = useRecoilState(displayedCountAtom);
    const [rowDeleted,setRowDeleted] = useRecoilState(deletRow);

    const [orderdEndDate, setOrdredEndDate] = useState('');
    const [orderdStartDate, setOrderedStartDate] = useState('');


    const [DownStartDateOrdering, setDownStartDateOrdering] = useState(false);
    const [upStartDateOrdering, setUpStartDateOrdering] = useState(false);
    const [defaultStartDateOrdering, setDefaultStartDateOrdering] = useState(true);



    const [DownEndDateOrdering, setDownEndDateOrdering] = useState(false);
    const [upEndDateOrdering, setUpEndDateOrdering] = useState(false);
    const [defaultEndDateOrdering, setDefaultEndDateOrdering] = useState(true);


    
    const [clearFiltersOfPhases,setClearFiltersOfPhases] = useState(false);


    const [valueOfStatusFilter, setValueOfStatusFilter] = useState('');
    const [labelOfStatusFilter,setLabelOfStatusFilter] = useState('Status');


    const [valueOfTeamFilter, setValueOfTeamFilter] = useState('');
    const [labelOfTeamFilter,setLabelOfTeamFilter] = useState('Team');

    const [labelOfSalesRepFilter,setLabelOfSalesRepFilter] = useState ('Sales Rep');
    const [valueOfSalesRepFilter,setValueOfSalesRepFilter] = useState ('');

    const [valueOfPhaseFilter, setValueOfPhaseFilter] = useState('');
    const [labelOfPhaseFilter, setLabelOfPhaseFilter] = useState('Phase');


    const startDateBefor = useRef('');
    const startDateAfter = useRef('');

    const endDateBefor = useRef('');
    const endDateAfter = useRef('');


    const [phases, setPhases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]); 
    const [showExportAll,setShowExportAll] = useState(false);
    const [showMoreFilter,setShowMoreFilters] = useState(false);
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const url = `${linkOfHost}/contacts-services/phases/`;
    const params = {
        offset: myOffSet,
        limit: 20,
        status:valueOfStatusFilter,
        phase:valueOfPhaseFilter,
        start_date_after:startDateAfter.current.value,
        start_date_before:startDateBefor.current.value,
        end_date_after:endDateAfter.current.value,
        end_date_before:endDateBefor.current.value,
        search:ser,
        ordering:`${orderdStartDate}${orderdEndDate}`,
        team_id:valueOfTeamFilter,
        sales_rep_id:valueOfSalesRepFilter
    };

    const getAllPhases = async () => {
        try {
            setLoading(false)
            const data = await axios.get(url, { params })
            .then((res) => {
                setPhases(res.data.results);
                setCount(res.data.count);
            });
            setLoading(true)
        } catch (err) {
            console.log(err);
        }
    };
    
    const handleSelectAll = (currentPage) => {
        const currentPhases = phases;
        const isAllSelected = currentPhases.every(phase => selectedRows[currentPage]?.some(row => row.id === phase.id));
        if (isAllSelected) {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: []
            }));
        } else {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: currentPhases
            }));
        }
    };
    

    const handleSelectRow = (phase, currentPage) => {
        const isSelected = selectedRows[currentPage]?.some(row => row.id === phase.id);
        if (isSelected) {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: prevSelected[currentPage]?.filter(row => row.id !== phase.id) || []
            }));
        } else {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: [...(prevSelected[currentPage] || []), phase]
            }));
        }
    };


    useEffect(() => {
        getAllPhases();
    }, [myOffSet,rowDeleted,apply]);
    
    useEffect(() => {
        if(clearFiltersOfPhases===false){
            getAllPhases();
        }
    }, [clearFiltersOfPhases]);
    
    useEffect(() => {
        setDisplayedCount(0);
        if (itemsNumber > 0) {
          const duration = 1500; 
          const stepTime = Math.abs(Math.floor(duration / itemsNumber)); 
          const counter = setInterval(() => {
            setDisplayedCount((prevCount) => {
              if (prevCount < itemsNumber) {
                return prevCount + 1; 
              } else {
                clearInterval(counter); 
                return prevCount;
              }
            });
          }, stepTime);
    
          return () => clearInterval(counter); 
        }
      }, [itemsNumber]);
      useEffect(()=>{
        const role = sessionStorage.getItem('role');
            if(role==='4'){
                setShowExportAll(true);
            }
            if(role==='3' || role==='4'){
                setShowMoreFilters(true);
            }
    },[])
    
    return (
        <div className="mt-5 mr-3 ml-6 sm:ml-0">
            {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <div className="mb-3 flex flex-row justify-between">
               
                    <div className="flex flex-row">
                        <ExportAllData url={`${linkOfHost}/contacts-services/all-phases-data/`} data='phases' setState={setIsLoading} />
                    </div>
                {
                    Object.values(selectedRows).some(rows => rows.length > 0) ? (
                        <div className="flex flex-row gap-x-3">
                            <Export pageName={'phases'} setIsLoading={setIsLoading} selectedRows={selectedRows} />
                        </div>
                    ) : null
                }
            </div>
            <hr className="mt-2 mb-1" />
                <div>
                    <div>
                    <form className="space-y-4 md:space-y-6" method="POST" onSubmit={(e) => {
                e.preventDefault();
            }}>
                <div className="flex  flex-row justify-between">
                    <div  className="flex flex-row w-[48%] gap-x-6">
                    <div className="w-[35%]">
                        <label htmlFor="dealNumber" className="block mb-2 text-sm font-medium">From Start Date :</label>
                        <input
                            ref={startDateAfter}
                            type="Date" name="dealnumber" id="number"
                            className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-1.5"
                            placeholder="Deal Number" required=""
                        />
                        <p className="text-red-500 text-sm pl-1">{}</p>
                    </div>
                    <div className="w-[35%]">
                        <label htmlFor="status" className="block mb-2 text-sm font-medium">To Start Date :</label>
                        <input
                            ref={startDateBefor}
                            type="Date" name="email" id="email"
                            className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-1.5"
                            placeholder="Email" required=""
                        />
                    </div>
                    </div>
                    <div className=" flex flex-row w-[48%] gap-x-6">
                    <div className="w-[35%]">
                        <label htmlFor="dealNumber" className="block mb-2 text-sm font-medium">From End Date :</label>
                        <input
                            ref={endDateAfter}
                            type="Date" name="dealnumber" id="number"
                            className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-1.5"
                            placeholder="Deal Number" required=""
                        />
                        <p className="text-red-500 text-sm pl-1">{}</p>
                    </div>
                    <div className="w-[35%]">
                        <label htmlFor="status" className="block mb-2 text-sm font-medium">To End Date :</label>
                        <input
                            ref={endDateBefor}
                            type="Date" name="email" id="email"
                            className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-1.5"
                            placeholder="Email" required=""
                        />
                    </div>
                    </div>
                </div>
            </form>
                    </div>
                </div>
            <hr className="mt-2 mb-3" />
            <div className="flex flex-row items-center justify-between text-lg text-gray-900  bg-[#4ab6c5dd] rounded-md h-10 w-full px-6 pr-[0.15rem]">
                <div className="flex flex-row ">
                    <PhaseFilter setValueOfPhaseFilter={setValueOfPhaseFilter} setClearFilters={setClearFiltersOfPhases} setLabelOfPhaseFilter={setLabelOfPhaseFilter} labelOfPhaseFilter={labelOfPhaseFilter} />
                    <StatusFilter labelOfoptionOne={labelOfOptionOneToStatusFilter} labelOfoptionTwo={labelOfOptionTwoToStatusFilter} valueOfOptionOne={valueOfOptionOneToStatusFilter} valueOfOptionTwo={valueOfOptionTwoToStatusFilter} page={'Phases'} setValueOfStatusFilter={setValueOfStatusFilter} setClearFilter={setClearFiltersOfPhases} setLabelOfStatusFilter={setLabelOfStatusFilter} labelOfStatusFilter={labelOfStatusFilter} />
                    {
                        showMoreFilter && (
                            <MoreFiltersOfPhases setClearFilter={setClearFiltersOfPhases} setLabelOfTeamFilter={setLabelOfTeamFilter} labelOfTeamFilter={labelOfTeamFilter} setValueOfTeamFilter={setValueOfTeamFilter}
                                setValueOfSalesRepFilter={setValueOfSalesRepFilter} setLabelOfSalesRepFilter={setLabelOfSalesRepFilter} labelOfSalesRepFilter={labelOfSalesRepFilter} />
                        )
                    }
                </div>
                <div className=" flex justify-self-end">
                <Tooltip
                className="z-30"
                anchorId={`tooltip`}       
                render={() => (
                <div>
                    <p className="text-left text-[16px]">filters</p>
                    <p>{ser!==''?(`search : ${ser}`):('')}</p>
                    <p>{valueOfStatusFilter!==''?(`Status : ${valueOfStatusFilter}`):('')}</p>
                    <p>{valueOfPhaseFilter!==''?(`Phase : ${labelOfPhaseFilter}`):(``)}</p>
                    <p>{valueOfSalesRepFilter!==''?(`Sales Rep : ${labelOfSalesRepFilter}`):('')}</p>
                    <p>{valueOfTeamFilter!==''?(`Team : ${labelOfTeamFilter}`):('')}</p>
                </div>
                )}
                />
                    {
                        clearFiltersOfPhases?(<button onClick={(e)=>{
                            document.getElementById('ser').value='';
                            setSer('');
                            setClearFiltersOfPhases(false);
                            setLabelOfPhaseFilter(labelOfPhaseFilter);
                            setValueOfPhaseFilter('');
                            setLabelOfStatusFilter('Status');
                            setValueOfStatusFilter('');
                            setValueOfSalesRepFilter('');
                            setLabelOfSalesRepFilter('Sales Rep');
                            setValueOfTeamFilter('');
                            setLabelOfTeamFilter('Team');
                        }} className=" font-sm text-white mx-3">Clear Filters
                        </button>):('')
                    }  
                        <div className="relative flex items-center w-80">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>    
                            <input
                                id="ser"
                                onChange={(e) => {setSer(e.target.value)
                                    setClearFiltersOfPhases(true)
                                }}
                                type="search"
                                className="block focus:outline-none w-full p-2 ps-10 pe-20 text-sm text-gray-900 rounded-lg bg-gray-50"
                                placeholder="Search"
                                required
                            />
                            <button
                                id="tooltip"
                                onClick={() => setApply(!apply)}
                                className="absolute inset-y-0 my-[0.8px] mr-[0.5px] end-0 px-4 text-white text-[16px] rounded-lg bg-blue-400 hover:bg-blue-500"
                            >
                                Apply
                            </button>
                        </div>
                </div>
            </div>
            <div className="flex flex-col mt-3">
                <div className="overflow-x-auto min-w-[1030px] pb-4">
                    <div className="min-w-full inline-block align-middle ">
                        <div className="overflow-hidden border-none rounded-lg">
                            <table className="table-auto min-w-[1100px] rounded-xl ">
                                <thead className="w-full">
                                    <tr className="bg-blue-100">
                                        <th className="">
                                            <div className="flex items-center px-5">
                                                <input
                                                    onChange={() => handleSelectAll(currentPage)} 
                                                    checked={phases.length > 0 && phases.every(phase => selectedRows[currentPage]?.some(row => row.id === phase.id))} 
                                                    type="checkbox" value="" className="w-5 h-5 appearance-none border border-gray-300 rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-center checked:border-indigo-500 checked:bg-indigo-100" />
                                            </div>
                                        </th>
                                        <th scope="col" className="w-[14%] text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Deal_Num </th>
                                        <th scope="col" className="w-[16%]  text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Contact </th>
                                        <th scope="col" className="w-[15%] text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Phase_Name </th>
                                        <th scope="col" className="w-[15%] text-left py-5 whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Start_Date{
                                        defaultStartDateOrdering===true?(<DefaultContactOrdering left={'left-[15px]'} top={'top-[22px]'} value={'start_date'} setValue={setOrderedStartDate} setDefaultOrdering={setDefaultStartDateOrdering} setDownOrdering={setDownStartDateOrdering} setUpOrdering={setUpStartDateOrdering} />):
                                        (upStartDateOrdering===true?(<UpContactOrdering left={'left-[73px]'} top={'top-[28px]'} value={'-start_date'} setValue={setOrderedStartDate} setDefaultOrdering={setDefaultStartDateOrdering} setDownOrdering={setDownStartDateOrdering} setUpOrdering={setUpStartDateOrdering} />):
                                        (<DownContactOrdering left={'left-[61px]'} top={'top-[28px]'} value={'start_date'} setValue={setOrderedStartDate} setDefaultOrdering={setDefaultStartDateOrdering} setDownOrdering={setDownStartDateOrdering} setUpOrdering={setUpStartDateOrdering} />))
                                        }</th>
                                        <th scope="col" className="w-[15%] text-left relative whitespace-nowrap text-sm  leading-6 font-semibold text-gray-900 capitalize"> End_Date {
                                        defaultEndDateOrdering===true?(<DefaultContactOrdering left={'left-[15px]'} top={'top-[22px]'} value={'end_date'} setValue={setOrdredEndDate} setDefaultOrdering={setDefaultEndDateOrdering} setDownOrdering={setDownEndDateOrdering} setUpOrdering={setUpEndDateOrdering} />):
                                        (upEndDateOrdering===true?(<UpContactOrdering left={'left-[68px]'} top={'top-[28px]'} value={'-end_date'} setValue={setOrdredEndDate} setDefaultOrdering={setDefaultEndDateOrdering} setDownOrdering={setDownEndDateOrdering} setUpOrdering={setUpEndDateOrdering} />):
                                        (<DownContactOrdering left={'left-[56px]'} top={'top-[28px]'} value={'end_date'} setValue={setOrdredEndDate} setDefaultOrdering={setDefaultEndDateOrdering} setDownOrdering={setDownEndDateOrdering} setUpOrdering={setUpEndDateOrdering} />))
                                        }</th>
                                        <th scope="col" className="w-[12%] text-left whitespace-nowrap text-sm leading-6 font-semibold text-gray-900 capitalize"> Status </th>
                                        <th scope="col" className=" bg-white text-left w-[10%]"><p className="inline-block rounded-md text-center text-[17px] ml-2 font-medium  text-white bg-[#4ab6c5dd] px-3 py-5 w-full">{displayedCount} Items</p>   </th> 
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300">
                                    {loading ? (
                                        phases.map((phase, i) => {
                                            let bg = '';
                                            if(i%2===0){
                                                bg='bg-white'
                                            }else{
                                                bg='bg-gray-100'
                                            }
                                            const currentPage = myOffSet / 20 + 1; 
                                            const isChecked = selectedRows[currentPage]?.some((row) => row.id === phase.id);
                                            return (
                                                <Phase setIsLoading={setIsLoading} bgColor={bg} key={phase.id} phase={phase} isChecked={isChecked} handleSelectRow={() => handleSelectRow(phase, currentPage)} />
                                            )
                                        })
                                    ) : (
                                            <div className=" absolute ml-[34%] mt-[10%]">
                                                <ClipLoader color="blue" size={100} />
                                            </div>
                                    )}
                                </tbody>
                            </table>
                            <PaginationOfContacts currentPage={currentPage} setCurrentPage={setCurrentPage} itemsNumber={itemsNumber} setOffset={setOffset} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}