import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { urlOfHost } from "../components/atoms";
import { useRecoilState } from "recoil";

export default function CreateNote({page}) {
  const [linkOfHost,] = useRecoilState(urlOfHost);
  const navigation = useNavigate();
  const [titleErr, setTitleErr] = useState('');
  const [noteErr, setNoteErr] = useState('');
  const params = useParams();
  const urlOfDeal=`${linkOfHost}/contacts-services/contacts/${params.contactId}/deals/${params.dealId}/notes/`;
  const urlOfContact=`${linkOfHost}/contacts-services/contacts/${params.contactId}/notes/`
  const noteName = useRef();
  const noteDescription = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e, setErrorState) => {
    e.target.style.border = '1px solid #3b82f6'; 
    setErrorState(''); 
};
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files); 
  };

  const uploadFiles = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", noteName.current.value);
    formData.append("note", noteDescription.current.value);
    // formData.append(`attachments`,[])
    selectedFiles.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });
      let url=``;
      if(page==='contact'){
        url=urlOfContact;
      }else{
        url=urlOfDeal;
      }
    axios
      .post(
        url,
        formData,
        config
      )
      .then((res) => {
        Swal.fire({
          icon:"success",
          title:"a not has been successfully created."
      })
      if(page==='contact'){
        navigation(`/contacts/contactDetail/${params.contactId}/notes`)
      }else if(page==='deal'){
        navigation(`/contacts/contactDetail/${params.contactId}/dealsOfContacts/dealDetail/${params.dealId}/notes`)
      }
      })
      .catch((err) => {
        console.log(err.response)
        if (err.response) {
          Object.keys(err.response.data).forEach((key)=>{
            if(key==='title'){
              const titleInput = document.getElementById("noteTitle");
              titleInput.style.border = '1px solid #CE4343';
              setTitleErr(err.response.data.title[0])
            }else if (key==='note'){
              const noteInput = document.getElementById("noteDescription");
              noteInput.style.border = '1px solid #CE4343';
              setNoteErr(err.response.data.note[0]);
            }
          })
        } else {
          Swal.fire({
            title:`${err.message}`,
            icon:"error"
          })
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <center className="flex flex-col pt-10 min-h-screen items-center">
      <div className="w-full max-w-lg border-[1px] border-indigo-300 mb-1">
        {isLoading && (
          <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
            <ClipLoader color="blue" loading={isLoading} size={150} />
          </div>
        )}
        <form
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8"
        >
          <h1 className="mb-5 font-bold leading-tight tracking-tight md:text-xl">
            Create a note
          </h1>

          <div className="mb-4">
            <label
              className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
              htmlFor="noteTitle"
            >
              Title of note
            </label>
            <input
              onChange={(e)=>{
                handleInputChange(e,setTitleErr);
              }}
              ref={noteName}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
              id="noteTitle"
              type="text"
              placeholder="Enter a title"
            />
            <p id="titleP" className="text-left text-[#CE4343] text-sm pl-1">{titleErr}</p>
          </div>
          <div className="mb-6">
            <label
              className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
              htmlFor="noteDescription"
            >
              Description of note
            </label>
            <input
              onChange={(e)=>{
                handleInputChange(e,setNoteErr);
              }}
              ref={noteDescription}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
              id="noteDescription"
              type="text"
              placeholder="Enter a description"
            />
            <p id="userP" className="text-left text-[#CE4343] text-sm pl-1">{noteErr}</p>
          </div>
          <div className="mb-4">
            <label className="block text-left text-gray-700 text-sm font-openSansBold mb-2">
              Attachment
            </label>
            <input
              type="file"
              multiple
              className="block w-full text-sm text-gray-900 py-1  cursor-pointer bg-gray-50 focus:outline-none focus:ring-primary-600 focus:border-primary-600"
              onChange={handleFileChange}
            />
            {selectedFiles.length > 1 && (
              <div className="mt-4 block text-left">
                <h2 className="font-semibold">Files selected:</h2>
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li key={index} className="mt-1 text-gray-700">
                      {file.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="flex items-center justify-between">
            <button
              onClick={uploadFiles}
              className="bg-blue-500 hover:bg-blue-600 text-white font-openSansBold py-2 px-4 rounded focus:outline-none"
              type="button"
            >
              create
            </button>
          </div>
        </form>
      </div>
    </center>
  );
}
