
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

export default function MainOfContact() {
    const location = useLocation();
    const params = useParams();

    const isActiveTab = (tab) => {
        if (location.pathname === `/contacts/contactDetail/${params.contactId}` && tab === 'general') {
            return true;
        }
        return location.pathname.includes(tab);
    };
    return (
        <div className=" ml-6 sm:ml-0 mb-5">
            <div className="flex flex-row mt-7">
                <Link to={'general'}>
                    <button
                        id="general"
                        className={`px-4 link font-medium rounded-md border-solid border-2 py-1 
                        ${isActiveTab('general') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        General
                    </button>
                </Link>
                <Link to={'dealsOfContact'}>
                    <button
                        id="dealsOfContact"
                        className={`px-4 link ml-6 font-medium rounded-md border-solid border-2 py-1 
                        ${isActiveTab('dealsOfContact') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        Deals
                    </button>
                </Link>
                <Link to={'notes'}>
                    <button
                        id="notes"
                        className={`px-4 link ml-6 font-medium rounded-md border-solid border-2 py-1 
                        ${isActiveTab('notes') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        Notes
                    </button>
                </Link>
                <Link to={'reminders'}>
                    <button
                        id="reminder"
                        className={`px-4 link ml-6 font-medium rounded-md border-solid border-2 py-1 
                        ${isActiveTab('reminders') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        Reminders
                    </button>
                </Link>
            </div>
            <hr className="mt-5 mb-3" />
            <Outlet />
        </div>
    );
}
