import { useEffect, useState } from "react";

import axios from "axios";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import {rowDeletedOfContacts, urlOfHost } from "../components/atoms";  
import WhiteContact from "../components/whitetrcontact";
import PaginationOfContacts from "../components/paginationofcontacts";
import SourceFilter from "../components/sourcefilter";
import StatusFilter from "../components/statusFilterOfContact";
import DefaultContactOrdering from "../components/defaultContactOrdering";
import UpContactOrdering from "../components/upContactOrdering";
import DownContactOrdering from "../components/downContactOrdering";
import ExportAllData from "../components/exportAllData";
import MoreFilters from "../components/moreFiltersOfContacts";
import Export from "../components/export";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";

export default function Contacts() {
    const [linkOfHost,] = useRecoilState(urlOfHost);

    const [labelOfOptionOneToStatusFilter,setLabelOfOptionOneToStatusFilter] = useState('Active');
    const [valueOfOptionOneToStatusFilter,setvalueOfOptionOneToStatusFilter] = useState('active');

    const [labelOfOptionTwoToStatusFilter,setLabelOfOptionTwoToStatusFilter] = useState('InActive');
    const [valueOfOptionTwoToStatusFilter,setValueOfOptionTwoToStatusFilter] = useState('inactive');


    const [apply,setApply] = useState(false)
    const [clearFilter, setClearFilter] = useState(false);

    const [labelOfCityFilter,setLabelOfCityFilter] = useState ('City');
    const [valueOfCityFilter,setValueOfCityFilter] = useState('');

    const [labelOfSalesRepFilter,setLabelOfSalesRepFilter] = useState ('Sales Rep');
    const [valueOfSalesRepFilter,setValueOfSalesRepFilter] = useState ('');


    const [labelOfSourceFilter,setLabelOfSourceFilter] = useState ('Source');
    const [valueOfSourceFilter,setValueOfSourceFilter] = useState ('');

    const [labelOfStatusFilter,setLabelOfStatusFilter] = useState ('Status');
    const [valueOfStatusFilter,setValueOfStatusFilter] = useState ('');

    const [ser,setSer] = useState ('');

    const [myOffSet, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsNumber, setCount] = useState(0);


    const [ordredMyContacBytId,setOrderedContactsById] = useState('');
    const [ordredMyContactByName,setOrderedContactsByName] = useState('');
    const [ordredMyContactByCity,setOrderedContactsByCity] = useState('');


    const [upOrderingById,setUpOrderingById] = useState(false);
    const [downOrderingById,setDownOrderingById] = useState(false);
    const [defaultOrderingById,setDefaultOrderingById] = useState(true);


    const [upOrderingByName,setUpOrderingByName] = useState(false);
    const [downOrderingByName,setDownOrderingByName] = useState(false);
    const [defaultOrderingByName,setDefaultOrderingByName] = useState(true);


    const [upOrderingByCity,setUpOrderingByCity] = useState(false);
    const [downOrderingByCity,setDownOrderingByCity] = useState(false);
    const [defaultOrderingByCity,setDefaultOrderingByCity] = useState(true);


    const [showAddContact , setShowAddContact] = useState (false);
    const [isLoading,setIsLoading] =useState(false);
    const [displayedCount, setDisplayedCount] = useState(0)
    const [contacts, setContacts] = useState([]);
    const [rowDeleted,setRowDeleted] = useRecoilState(rowDeletedOfContacts);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]); 
    const url = `${linkOfHost}/contacts-services/contacts/`;
    const params = {
        offset: myOffSet,
        limit: 20,
        source:valueOfSourceFilter,
        status:valueOfStatusFilter,
        search:ser,
        ordering: `${ordredMyContacBytId},${ordredMyContactByName},${ordredMyContactByCity}`,
        city:valueOfCityFilter,
        assign_to :valueOfSalesRepFilter
    };
    const getAllContacts = async () => {
        try {
            setLoading(false)
        const data = await axios.get(url,{params})
            .then((res) => {
                setContacts(res.data.results);
                setCount(res.data.count);
            });
            setLoading(true)
        } catch (err) {
            console.log(err);
        }
    };
    const handleSelectAll = (currentPage) => {
        const currentContacts = contacts;  
        const isAllSelected = currentContacts.every(contact => selectedRows[currentPage]?.some(row => row.id === contact.id));
        if (isAllSelected) {    
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: []
            }));
        } else {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: currentContacts
            }));
        }
    };
    

    const handleSelectRow = (contact, currentPage) => {
        const isSelected = selectedRows[currentPage]?.some(row => row.id === contact.id);
        if (isSelected) {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: prevSelected[currentPage]?.filter(row => row.id !== contact.id) || []
            }));
        } else {
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: [...(prevSelected[currentPage] || []), contact]
            }));
        }
    };
    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const selectedContacts = Object.values(selectedRows).flat();
                if (selectedContacts.length === 0) return;
                    await Promise.all(
                    selectedContacts.map(contact =>
                        axios.delete(`${linkOfHost}/contacts-services/contacts/${contact.id}/`)
                    )
                );
                setIsLoading(false)
                setSelectedRows({});
                Swal.fire({
                    title:"Contacts deleted successfully",
                    icon:"success"
                    })
                setRowDeleted(!rowDeleted);
        } catch (error) {
            setIsLoading(false)
            console.error("Error deleting users:", error);
        }
    };
    
    useEffect(() => {
        getAllContacts();
    }, [myOffSet,rowDeleted,apply]);
    useEffect(() => {
        if(clearFilter===false){
            getAllContacts();
        }
    }, [clearFilter]);
    
    useEffect(() => {
        setDisplayedCount(0);
        if (itemsNumber > 0) {
          const duration = 1500; // مدة العد بالميلي ثانية (هنا ثانية واحدة)
          const stepTime = Math.abs(Math.floor(duration / itemsNumber)); // الزمن بين كل تحديث
            const counter = setInterval(() => {
            setDisplayedCount((prevCount) => {
            if (prevCount < itemsNumber) {
                return prevCount + 1; // زيادة العداد بمقدار 1
            } else {
                clearInterval(counter); // وقف العداد عند الوصول إلى العدد الفعلي
                return prevCount;
            }
            });
        }, stepTime);
    
          return () => clearInterval(counter); // تنظيف التايمر عند انتهاء التأثير أو تحديث الكومبوننت
        }
    }, [itemsNumber]);
    useEffect(()=>{
        const role = sessionStorage.getItem('role');
            if(role==='4' || role === '3'){
                setShowAddContact(true)
            }
    },[])
    return (
        <div className="mt-5 mr-3 ml-6 sm:ml-0">
            {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <div className="mb-3 flex flex-row justify-between">
                {showAddContact&&(
                        <div className="flex flex-row">
                            <button className="mr-5">
                                <Link to={"/contacts/addcontact"} className="px-4 text-white font-medium rounded-lg py-2 bg-[#37c] hover:bg-[#5491db]">
                                    Add Contact
                                </Link>
                            </button>
                                <ExportAllData url={`${linkOfHost}/contacts-services/all-contacts-data/`} setState={setIsLoading} />
                            </div>
                )}
                    {
                        Object.values(selectedRows).some(rows => rows.length > 0) ? (
                            <div className="flex flex-row gap-x-3">
                                <button onClick={handleDelete}className="bg-red-500 hover:bg-red-600 px-2 py-1 text-white rounded-lg">Delete Selected</button>
                                <Export pageName={'contacts'} setIsLoading={setIsLoading} selectedRows={selectedRows} />
                            </div>
                        ) : null
                    }
                <Tooltip
                className="z-30"
                anchorId={`tooltip`}       
                render={() => (
                    <div>
                    <p className="text-left text-[16px]">filters</p>
                    <p>{ser!==''?(`search : ${ser}`):('')}</p>
                    <p>{valueOfStatusFilter!==''?(`Status : ${valueOfStatusFilter}`):('')}</p>
                    <p>{valueOfSourceFilter!==''?(`Source : ${valueOfSourceFilter}`):(``)}</p>
                    <p>{valueOfCityFilter!==''?(`City : ${labelOfCityFilter}`):(``)}</p>
                    <p>{valueOfSalesRepFilter!==''?(`Sales Rep : ${labelOfSalesRepFilter}`):(``)}</p>
                    </div>
                )}
            />
            </div>
            <hr className="mt-2 mb-3" />
            <div className="flex flex-row items-center justify-between text-lg text-gray-900  bg-[#4ab6c5dd] rounded-md h-10 w-full px-6 pr-[0.15rem]">
                <div className="flex flex-row ">
                    <SourceFilter setClearFilter={setClearFilter} setValueOfSourceFilter={setValueOfSourceFilter} setLabelOfSourceFilter={setLabelOfSourceFilter} labelOfSourceFilter={labelOfSourceFilter} />
                    <StatusFilter labelOfoptionOne={labelOfOptionOneToStatusFilter} labelOfoptionTwo={labelOfOptionTwoToStatusFilter} valueOfOptionOne={valueOfOptionOneToStatusFilter} valueOfOptionTwo={valueOfOptionTwoToStatusFilter} setClearFilter={setClearFilter} setValueOfStatusFilter={setValueOfStatusFilter} setLabelOfStatusFilter={setLabelOfStatusFilter} labelOfStatusFilter={labelOfStatusFilter} />
                    <MoreFilters setClearFilter={setClearFilter} setLabelOfCityFilter={setLabelOfCityFilter} labelOfCityFilter={labelOfCityFilter} setValueOfCityFilter={setValueOfCityFilter}
                        setValueOfSalesRepFilter={setValueOfSalesRepFilter} setLabelOfSalesRepFilter={setLabelOfSalesRepFilter} labelOfSalesRepFilter={labelOfSalesRepFilter}/>
                </div>
                <div className=" flex justify-self-end">
            {
                clearFilter?(  <button onClick={(e)=>{
                    setClearFilter(false);

                    setLabelOfCityFilter('City');
                    setValueOfCityFilter('');

                    setLabelOfSalesRepFilter('sales_Rep');
                    setValueOfSalesRepFilter('');

                    setLabelOfSourceFilter('Source')
                    setValueOfSourceFilter('')


                    setLabelOfStatusFilter('Status')
                    setValueOfStatusFilter('')

                    setSer('');
                    document.getElementById('default-search').value='';

                }} className=" font-sm text-white mx-3">Clear Filters
                </button>):('')
            }  
                <div className="relative flex items-center w-80">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input onChange={(e)=>{
                        setSer(e.target.value);
                        setClearFilter(true);
                    }} type="search" id="default-search" className="block focus:outline-none focus:border-none w-80 p-2 ps-10 text-sm text-gray-900  rounded-lg bg-gray-50 " placeholder="Search" required />
                    <button
                    id="tooltip"
                    onClick={() => setApply(!apply)}
                    className="absolute inset-y-0 my-[0.8px] mr-[0.5px] end-0 px-4 text-white text-[16px] rounded-lg bg-blue-400 hover:bg-blue-500"
                >
                    Apply
                </button>
                </div>
        </div>
            </div>
            <div className="flex flex-col mt-3">
                <div className="overflow-x-auto min-w-[1060px] pb-4">
                    <div className="min-w-full inline-block align-middle">
                        <div className="overflow-hidden border-none rounded-lg ">
                            <table className="table-auto rounded-xl">
                                <thead className="w-full">
                                    <tr className="bg-blue-100">
                                        <th className="">
                                            <div className="flex items-center px-5 ">
                                            <input
                                                    onChange={() => handleSelectAll(currentPage)} 
                                                    checked={contacts.length > 0 && contacts.every(user => selectedRows[currentPage]?.some(row => row.id === user.id))} 
                                                    type="checkbox" value="" className="w-5 h-5 appearance-none border border-gray-300 rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-center checked:border-indigo-500 checked:bg-indigo-100" />
                                            </div>
                                        </th>
                                        <th scope="col" className="w-[12%] text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Contact ID
                                        {defaultOrderingById===true?(<DefaultContactOrdering  value={'id'} setUpOrdering={setUpOrderingById} setDownOrdering={setDownOrderingById} setDefaultOrdering={setDefaultOrderingById} setValue={setOrderedContactsById} />)
                                        :(upOrderingById===true?(<UpContactOrdering left={'left-[76px]'} top={'top-[28px]'} value={'-id'} setUpOrdering={setUpOrderingById} setDownOrdering={setDownOrderingById} setDefaultOrdering={setDefaultOrderingById} setValue={setOrderedContactsById}  />)
                                        :(<DownContactOrdering left={'left-[64px]'} top={'top-[28px]'} value={'id'} setUpOrdering={setUpOrderingById} setDownOrdering={setDownOrderingById} setDefaultOrdering={setDefaultOrderingById} setValue={setOrderedContactsById} />))}
                                        </th>
                                        <th scope="col" className="w-[12%] text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Full Name
                                        {defaultOrderingByName===true?(<DefaultContactOrdering value={'full_name_en'} setValue={setOrderedContactsByName} setDownOrdering={setDownOrderingByName} setDefaultOrdering={setDefaultOrderingByName} setUpOrdering={setUpOrderingByName} />)
                                        :(upOrderingByName===true?(<UpContactOrdering left={'left-[72px]'} top={'top-[28px]'} value={'-full_name_en'} setValue={setOrderedContactsByName} setDownOrdering={setDownOrderingByName} setDefaultOrdering={setDefaultOrderingByName} setUpOrdering={setUpOrderingByName} />)
                                        :(<DownContactOrdering left={'left-[60px]'} top={'top-[28px]'} value={'full_name_en'} setValue={setOrderedContactsByName} setDownOrdering={setDownOrderingByName} setDefaultOrdering={setDefaultOrderingByName} setUpOrdering={setUpOrderingByName} />))}
                                        </th>
                                        <th scope="col" className="w-1/12 text-left whitespace-nowrap relative text-sm  leading-6 font-semibold text-gray-900 capitalize">City
                                        {defaultOrderingByCity===true?(<DefaultContactOrdering value={'city'} setValue={setOrderedContactsByCity} setUpOrdering={setUpOrderingByCity} setDownOrdering={setDownOrderingByCity} setDefaultOrdering={setDefaultOrderingByCity} />)
                                        :(upOrderingByCity===true?(<UpContactOrdering left={'left-[32px]'} top={'top-[28px]'} value={'-city'} setValue={setOrderedContactsByCity} setUpOrdering={setUpOrderingByCity} setDownOrdering={setDownOrderingByCity} setDefaultOrdering={setDefaultOrderingByCity} />)
                                        :(<DownContactOrdering left={'left-[20px]'} top={'top-[28px]'} value={'city'} setValue={setOrderedContactsByCity} setUpOrdering={setUpOrderingByCity} setDownOrdering={setDownOrderingByCity} setDefaultOrdering={setDefaultOrderingByCity} />))}
                                        </th>
                                        <th scope="col" className="w-2/12 text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Phone_number</th>
                                        <th scope="col" className="w-1.5/12 text-left whitespace-nowrap text-sm  leading-6 font-semibold text-gray-900 capitalize"> Source </th>
                                        <th scope="col" className="w-1.5/12 text-left whitespace-nowrap text-sm  leading-6 font-semibold text-gray-900 capitalize"> Sales_REP </th>
                                        <th scope="col" className="w-1/12 text-left whitespace-nowrap text-sm leading-6 font-semibold text-gray-900 capitalize"> Status </th>
                                        <th scope="col" className="w-1/12 bg-white text-left "><p className="inline-block rounded-md text-center text-[17px] ml-2 font-medium  text-white bg-[#4ab6c5dd] px-3 py-5 w-full">{displayedCount} Items</p>  </th> 
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300">
                                    {loading ? (
                                        contacts.map((contact, i) => {
                                            const currentPage = myOffSet / 20 + 1;  
                                            const isChecked = selectedRows[currentPage]?.some((row) => row.id === contact.id);
                                            let bg  = '';
                                            if(i%2===0){
                                                bg='bg-white'
                                            }else{
                                                bg='bg-gray-100'
                                            }
                                            return (
                                                <WhiteContact setIsLoading={setIsLoading} bgColor={bg}  key={contact.id} contact={contact} isChecked={isChecked} handleSelectRow={() => handleSelectRow(contact, currentPage)} />
                                            ) 
                                        })
                                    ) : (
                                        <div className="fixed inset-0 flex items-center justify-center">
                                            <ClipLoader color="blue" size={100} />
                                        </div>
                                    )}
                                </tbody>
                            </table>
                            <PaginationOfContacts currentPage={currentPage} setCurrentPage={setCurrentPage} itemsNumber={itemsNumber} setOffset={setOffset} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}