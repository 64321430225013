import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { urlOfHost } from "../components/atoms";
import { useRecoilState } from "recoil";

export default function DetailOfReminder (){
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const location = useLocation();
    const fromPage = location.state?.from;
    const dealId = location.state?.dealId;
    const navigation = useNavigate();
    const [titleErr, setTitleErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');
    const [dateErr, setDateErr] = useState('');
    const [emailErr, setEmailErr]= useState ('');
    const params = useParams();
    const title = useRef();
    const Description = useRef();
    const email = useRef();
    const date = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const url = `${linkOfHost}/contacts-services/contacts/${params.contactId}/reminders/${params.reminderId}`;
    const handleInputChange = (e, setErrorState) => {
    e.target.style.border = '1px solid #3b82f6'; 
    setErrorState(''); 
};
    const handleDetail = async () => {
        console.log(url)
        try{
            const data = await axios
            .get(url)
            .then(res => {
                title.current.value=res.data.title;
                Description.current.value = res.data.description;
                email.current.value=res.data.email;
                date.current.value=res.data.date
            });
        } catch(err) {
            console.log(err)
        }
};
const handelUpdate = () => {
    setIsLoading(true);
    axios
    .put(
        `${linkOfHost}/contacts-services/contacts/${params.contactId}/reminders/${params.reminderId}`,
        {
            title:title.current.value,
            description:Description.current.value,
            email:email.current.value,
            date:date.current.value
        }
    )
    .then((res) => {
        Swal.fire({
        icon:"success",
        title:"Modified Successfully"
    })
    if(fromPage==='contact'){
        navigation(`/contactDetail/${params.contactId}/reminders`)
    }else if(fromPage==='deal'){
        navigation(`/contactDetail/${params.contactId}/deals/dealDetail/${dealId}/reminders`)
    }
    })
    .catch((err) => {
        if (err.response) {
            console.log(err.response)
        Object.keys(err.response.data).forEach((key)=>{
            if(key==='title'){
            const titleInput = document.getElementById("title");
            titleInput.style.border = '1px solid #CE4343';
            setTitleErr(err.response.data.title[0])
            }else if (key==='description'){
            const noteInput = document.getElementById("description");
            noteInput.style.border = '1px solid #CE4343';
            setDescriptionErr(err.response.data.description[0]);
            }else if(key==="email"){
                const emailInput = document.getElementById('email');
                emailInput.style.border = '1px solid #CE4343';
                setEmailErr(err.response.data.email[0]);
            }else if(key==='date'){
                const dateInput = document.getElementById('date');
                dateInput.style.border = '1px solid #CE4343';
                setDateErr(err.response.data.date[0]);
            }
        })
        } else {
        Swal.fire({
            title:`${err.message}`,
            icon:"error"
        })
        }
    })
    .finally(() => {
        setIsLoading(false);
    });
};

    useEffect(()=>{
        handleDetail();
    },[])
    return (
        <center className="flex flex-col pt-10 bg-gray-50 min-h-screen items-center">
        <div className="w-full max-w-lg border-[1px] border-indigo-300 mb-1">
            {isLoading && (
            <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                <ClipLoader color="blue" loading={isLoading} size={150} />
            </div>
            )}
            <form
            method="POST"
            onSubmit={(e) => {
                e.preventDefault();
            }}
            className="bg-white shadow-md rounded px-8 pt-6 pb-8"
            >
            <h1 className="mb-5 font-bold leading-tight tracking-tight md:text-xl">
                Edit a Reminder
            </h1>
            <div className="mb-4">
                <label
                className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
                htmlFor="noteTitle"
                >
                Title of Reminder
                </label>
                <input
                onChange={(e)=>{
                    handleInputChange(e,setTitleErr);
                }}
                ref={title}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                id="title"
                type="text"
                placeholder="Enter a title"
                />
                <p id="titleP" className="text-left text-[#CE4343] text-sm pl-1">{titleErr}</p>
            </div>
            <div className="mb-6">
                <label
                className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
                htmlFor="noteDescription"
                >
                Description of Reminder
            </label>
            <textarea
                onChange={(e)=>{
                    handleInputChange(e,setDescriptionErr);
                }}
                ref={Description}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                id="description"
                type="text"
                placeholder="Enter a description"
            />
            <p id="userP" className="text-left text-[#CE4343] text-sm pl-1">{descriptionErr}</p>
            </div>
            <div className="mb-6">
                <label
                className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
                htmlFor="email"
                >
                Email
                </label>
                <input
                onChange={(e)=>{
                    handleInputChange(e,setEmailErr);
                }}
                ref={email}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                id="email"
                type="email"
                placeholder="Enter an Email"
                />
                <p id="emailP" className="text-left text-[#CE4343] text-sm pl-1">{emailErr}</p>
            </div>
            <div className="mb-6">
            <label
                className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
                htmlFor="date"
            >
                Date of Reminder
            </label>
            <input
                onChange={(e)=>{
                    handleInputChange(e,setDateErr);
                }}
                ref={date}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                id="date"
                type="date"
                />
                <p id="dateP" className="text-left text-[#CE4343] text-sm pl-1">{dateErr}</p>
            </div>
            <div className="flex items-center">
                <button
                onClick={handelUpdate}
                className="bg-blue-500 mr-5 hover:bg-blue-600 text-white font-openSansBold py-2 px-4 rounded focus:outline-none"
                type="button"
                >
                save
                </button>
                <button onClick={(e)=>{
                    title.current.value='';
                    date.current.value='';
                    email.current.value='';
                    Description.current.value = '';
                }}
                    className="px-4 py-2 bg-red-500 hover:bg-red-600 font-medium rounded-md text-white">
                        Delete
                </button>
            </div>
            </form>
        </div>
        </center>
    );
}