import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { urlOfHost } from "./atoms";


export default function AddPhase ({setAddPhase,setChangePhase,changePhase}){
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const [isLoading,setIsLoading] = useState(false);
    const [valueOfNewPhase,setValueOfNewPhase] = useState (2);
    const [phaseErr,setPhaseErr] = useState('');
    const params = useParams ();
    const url = `${linkOfHost}/contacts-services/contacts/${params.contactId}/deals/${params.dealId}/phases/`
    const handelAddPhase = ()=>{
        setIsLoading(true);
        axios.post(url,{
            phase:valueOfNewPhase
        }).then((res)=>{
            setAddPhase(false);
            setChangePhase(!changePhase);
            Swal.fire({
                title:"You have successfully moved to the specified stage.",
                icon:"success"
            })
        }).catch((err)=>{
            if(err.response){
                Object.keys(err.response.data).forEach(key => {
                    if (key === "error") {
                        setPhaseErr(err.response.data.error[0]);
                    }
                });
            }else{
                Swal.fire({
                    title:`${err.message}`,
                    icon:"error"
                })
            }
        }).finally(()=>{
            setIsLoading(false)
        })
    }
    return(
        <div className="fixed inset-0 z-50 flex p-0 m-0 items-center justify-center bg-black bg-opacity-50">
        <div className="p-6 w-[450px]">
            {isLoading && (
                <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <form
            method="POST"
            onSubmit={(e) => {
                e.preventDefault();
            }}
            className="bg-white px-8 pt-6 pb-8"
            >
            <p className=" font-medium leading-tight tracking-tight md:text-lg mb-3">select phase to change current phase :</p>
            <select onChange={(e)=>{
                setPhaseErr('');
                setValueOfNewPhase(e.target.value);
                }} className=" w-full bg-blue-500 text-white py-2 px-2 rounded-lg">
                    <option value={2} className=" text-black bg-gray-200 ">Check-In</option>
                    <option value= {3} className=" text-black bg-gray-200">Discuss Term</option>
                    <option value={4}  className=" text-black bg-gray-200 ">Seal The Deal</option>
                </select>
                <p id="priceP" className="text-left text-[#CE4343] text-sm pl-1 mb-6">{phaseErr}</p>
                <div className="flex items-center">
                <button onClick={handelAddPhase}
                    className="mr-4 bg-blue-500 hover:bg-blue-600 text-white font-openSansBold py-1 px-4 rounded focus:outline-none"
                    type="button"
                >
                    Change
                </button>
                <button onClick={(e)=>{
                    setAddPhase(false);
                }}
                    className="bg-red-500 hover:bg-red-600 text-white font-openSansBold py-1 px-4 rounded focus:outline-none"
                    type="button"
                >
                    Cancel
                </button>
                </div>
            </form>
            </div>
            </div>
    )
}