import React, { useState, useEffect, useRef } from "react";
import { citiesByCountry } from "./cites";
import axios from "axios";
import { useRecoilState } from "recoil";
import { urlOfHost } from "./atoms";

const MoreFilters = (props) => {
  const [linkOfHost,] = useRecoilState(urlOfHost);
  const [showSalesRepFilter, setShowSalesRepFilter] = useState(false);
  const [salesReps, setSalesReps] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null); // الحالة الجديدة لتحديد الخيار المفتوح
  const dropdownRef = useRef(null);
  const url = `${linkOfHost}/auth/assigned-sales-reps/`;
  const getAllSalesReps = () => {
    axios
      .get(url)
      .then((res) => {
        setSalesReps(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setActiveFilter(null); 
  };

  const handleSubMenuClick = (filter) => {
    if (activeFilter === filter) {
      setActiveFilter(null); 
    } else {
      setActiveFilter(filter); 
    }
  };

  const handleFilterSelect = () => {
    props.setClearFilter(true);
    setIsOpen(false); 
    setActiveFilter(null); 
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setActiveFilter(null);
    }
  };

  useEffect(() => {
    const role = sessionStorage.getItem("role");
    if (role === "4" || role === "3") {
      setShowSalesRepFilter(true);
      getAllSalesReps();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        id="dropdownAvatarNameButton"
        onClick={toggleDropdown}
        className="flex items-center text-sm pe-1 font-medium text-white md:me-0"
        type="button"
      >
        <span className="sr-only">Open Sources menu</span>
        <span className="font-bold">More Filters</span>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownAvatarName"
          className="absolute top-7 left-[-13px] z-[2] bg-gray-50 divide-y divide-gray-100 rounded-lg shadow w-44"
        >
          <ul className="py-2 text-sm text-gray-700">
            <li
              className="hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSubMenuClick("city")}
            >
              <div className="block px-4 py-2 text-gray-900">
                {props.labelOfCityFilter}
              </div>
              {activeFilter === "city" && (
                <ul className="absolute top-0 left-[180px] max-h-44 overflow-y-auto bg-gray-50 rounded-lg shadow w-48 py-2 text-sm text-gray-700">
                  {citiesByCountry["SA"].map((city) => {
                    return (
                      <li
                        className="hover:bg-gray-100 cursor-pointer px-4 py-2"
                        onClick={() => {
                          props.setValueOfCityFilter(city.value);
                          handleFilterSelect();
                          props.setLabelOfCityFilter(city.label);
                        }}
                      >
                        {city.label}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
            {showSalesRepFilter && (
              <li
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSubMenuClick("sales_Rep")}
              >
                <div className="block px-4 py-2 text-gray-900">
                  <button>{props.labelOfSalesRepFilter}</button>
                </div>
                {activeFilter === "sales_Rep" && (
                  <ul className="absolute top-0 left-[180px] bg-gray-50 rounded-lg shadow overflow-y-auto max-h-44 w-48 py-2 text-sm text-gray-700">
                    {salesReps.map((salesRep) => {
                      return (
                        <li
                          key={salesRep.id}
                          onClick={() => {
                            handleFilterSelect();
                            props.setLabelOfSalesRepFilter(
                              salesRep.full_name_en
                            );
                            props.setValueOfSalesRepFilter(salesRep.id);
                          }}
                          className="hover:bg-gray-100 cursor-pointer px-4 py-2"
                        >
                          <button>{salesRep.full_name_en}</button>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MoreFilters;
