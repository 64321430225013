import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { urlOfHost, verifyEmail } from "../components/atoms";

export default function Forgetpassword (){
  const [linkOfHost,] = useRecoilState(urlOfHost);
  const navigation = useNavigate();
    const [email,setEmail] = useRecoilState (verifyEmail)
    const [emailErr,setEmailErr] = useState ('')
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = ()=>{
        setIsLoading(true);
        axios.post(`${linkOfHost}/auth/users/send-reset-code/`,{
            email:email
        }).then(res=>{
          if(res.status===200){
            localStorage.setItem("email",email);
            navigation('/emailverification');
          }
          const inputs = document.querySelectorAll('input');
          inputs.forEach(input => {
              input.value = '';
          });
        }).catch(err=>{
          if(err.response){
            document.getElementById('email').style.outline=''
                setEmailErr('');
                console.log(err.response.data.email[0])
                Object.keys(err.response.data).forEach((key)=>{
                    if(key==="email"){
                        setEmailErr(err.response.data.email);
                        document.getElementById('email').style.border='2px solid  #CE4343'
                    }
                })
          }else{
            console.log(err)
          }
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }
    return(
      <center>
            <main id="content" role="main" className="w-full bg-gray-50 min-h-screen  mx-auto p-6">
                {isLoading && (
                    <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                        <ClipLoader color="blue" loading={isLoading} size={150} />
                    </div>
                )}
        <div className="mt-6 bg-white max-w-md rounded-xl shadow-lg border-[1px] border-indigo-300">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h1 className="block text-2xl font-bold text-gray-800 ">Forgot password?</h1>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Remember your password?
                <Link to={"/"} className="text-blue-500 hover:text-blue-700 decoration-2  font-medium">
                  Login here
                </Link>
              </p>
            </div>
            <div className="mt-5">
              <form method="POST" onSubmit={(e)=>{
                    e.preventDefault();
                }}>
                <div className="grid gap-y-4">
                  <div>
                    <label htmlFor="email" className="block text-sm font-bold ml-1 mb-2 dark:text-white">Email address</label>
                    <div className="relative">
                        <label htmlFor="email" className=" pl-1 block mb-2 text-sm font-medium ">Email</label>
                        <input onChange={(e)=>{
                            e.target.style.border = '1px solid #3b82f6';
                            setEmailErr(''); 
                            setEmail(e.target.value);
                        }} type="email" id="email" name="email" placeholder="Enter Your Email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none" required aria-describedby="email-error" />
                    </div>
                    <p className="pl-1 text-left text-xs text-[#CE4343] mt-2" id="email-error">{emailErr}</p>
                  </div>
                  <button onClick={handleSubmit} type="submit" className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500  transition-all text-sm dark:focus:ring-offset-gray-800">Reset password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <p className="text-center text-gray-500 text-xs mt-2">
              &copy;2024 AxisOrch. All rights reserved.
        </p>
      </main>
    </center>
        
    )
}