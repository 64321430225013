import React, { useState, useEffect, useRef } from 'react';

const PhaseFilter = ({setValueOfPhaseFilter,setClearFilters,setLabelOfPhaseFilter, labelOfPhaseFilter}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        id="dropdownAvatarNameButton"
        onClick={toggleDropdown}
        className="flex items-center text-sm pe-1 font-medium text-white md:me-0"
        type="button"
      >
        <span className="sr-only">Open user menu</span>
        <span className="font-bold">{labelOfPhaseFilter}</span>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownAvatarName"
          className="absolute top-7 left-[-13px] z-10 bg-gray-50 divide-y divide-gray-100 rounded-lg shadow w-44"
        >
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
            <li onClick={(e)=>{
                setClearFilters(true)
                setLabelOfPhaseFilter('Send Quote')
                setValueOfPhaseFilter(1);
                setIsOpen(false)
            }} className=' hover:bg-gray-100 cursor-pointer'>
              <button
                className="block px-4 py-2 text-gray-900"
              >
                Send Quote
              </button>
            </li>
            <li onClick={(e)=>{
                  setClearFilters(true)
                  setLabelOfPhaseFilter('Check In')
                  setValueOfPhaseFilter(2)
                  setIsOpen(false)
            }} className=' hover:bg-gray-100 cursor-pointer'>
              <button
                className="block px-4 py-2 text-gray-900"
              >
                Check In
              </button>
            </li>
            <li onClick={(e)=>{
                  setClearFilters(true)
                  setLabelOfPhaseFilter('Discuss Term')
                  setValueOfPhaseFilter(3)
                  setIsOpen(false)
            }} className=' hover:bg-gray-100 cursor-pointer'>
              <button
                className="block px-4 py-2 text-gray-900"
              >
                Discuss Term
              </button>
            </li>
            <li onClick={(e)=>{
                  setClearFilters(true)
                  setValueOfPhaseFilter(4)
                  setLabelOfPhaseFilter('Sale The Deal')
                  setIsOpen(false)
            }} className=' hover:bg-gray-100 cursor-pointer'>
              <button
                className="block px-4 py-2 text-gray-900"
              >
                Sale The Deal
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default PhaseFilter;
