import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import { MdDashboard } from 'react-icons/md';
import { FaUsers, FaHandshake } from 'react-icons/fa';
import { AiOutlineContacts } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { MdGroupWork } from 'react-icons/md';
import { FaRegEye } from 'react-icons/fa';

export default function SideBar() {
    const [role, setRole] = useState(null);
    const [usersAndDashBaordShowOrHide, setUsersAndDashBaordShowOrHide] = useState(false);
    const [teamsShowOrHide, setTeamsShowOrHide] = useState(false);
    const location = useLocation();

    const isActiveTab = (tab) => {
        if (location.pathname === `/` && tab === 'dashboard') {
            return true;
        }
        return location.pathname.includes(tab);
    };

    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        const role = sessionStorage.getItem('role');
        setRole(role);
        if (role === '4') {
            setUsersAndDashBaordShowOrHide(true);
        }
        if (role === '3' || role === '4') {
            setTeamsShowOrHide(true);
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex">
            <button
                onClick={toggleSidebar}
                aria-controls="default-sidebar"
                type="button"
                className="fixed top-0 bg-gray-50 left-0 p-2 mt-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-gray-200"
            >
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>
            <aside
                ref={sidebarRef}
                id="default-sidebar"
                className={`fixed top-0 left-0 z-50 w-60 h-screen bg-[#F7F7F7] transition-transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} sm:translate-x-0`}
                aria-label="Sidebar"
            >
                <div className="flex flex-col h-full px-3 py-4 overflow-y-auto sm:overflow-y-hidden">
                    <div className='my-12 flex flex-row justify-start'>
                    <svg width='250px' id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1070.55 281.3">
                        <path fill='#103b7a' className="cls-1" d="M140.72,148.19H46.61l-19.48,44.27H1.07L81.27,15.37h25.05l80.45,177.09h-26.56l-19.48-44.27ZM131.87,127.95l-38.2-86.78-38.2,86.78h76.4Z"/>
                        <path fill='#103b7a' className="cls-1" d="M286.69,192.46l-38.71-51.36-39.21,51.36h-27.07l52.87-68.56-50.34-65.53h27.07l36.94,48.32,36.68-48.32h26.56l-50.6,65.53,53.38,68.56h-27.58Z"/>
                        <path fill='#103b7a' className="cls-1" d="M341.21,28.02c-3.12-3.04-4.68-6.74-4.68-11.13s1.56-8.13,4.68-11.26c3.12-3.12,7.04-4.68,11.76-4.68s8.64,1.48,11.76,4.43c3.12,2.95,4.68,6.62,4.68,11s-1.56,8.39-4.68,11.51c-3.12,3.12-7.04,4.68-11.76,4.68s-8.65-1.52-11.76-4.55ZM340.83,58.38h24.29v134.08h-24.29V58.38Z"/>
                        <path fill='#103b7a' className="cls-1" d="M418.25,189.55c-10.12-2.95-18.05-6.7-23.78-11.26l10.12-19.23c5.9,4.22,13.07,7.59,21.5,10.12,8.43,2.53,16.95,3.8,25.55,3.8,21.25,0,31.88-6.07,31.88-18.22,0-4.05-1.43-7.25-4.3-9.61-2.87-2.36-6.45-4.09-10.75-5.19-4.3-1.09-10.42-2.32-18.34-3.67-10.79-1.68-19.61-3.62-26.44-5.82-6.83-2.19-12.69-5.9-17.58-11.13-4.89-5.23-7.34-12.56-7.34-22.01,0-12.14,5.06-21.88,15.18-29.22,10.12-7.34,23.69-11.01,40.73-11.01,8.94,0,17.88,1.1,26.82,3.29,8.94,2.19,16.27,5.15,22.01,8.85l-10.37,19.23c-10.97-7.08-23.87-10.63-38.71-10.63-10.29,0-18.13,1.69-23.53,5.06-5.4,3.38-8.1,7.84-8.1,13.41,0,4.39,1.52,7.84,4.55,10.37,3.04,2.53,6.79,4.39,11.26,5.57,4.47,1.18,10.75,2.53,18.85,4.05,10.79,1.86,19.52,3.84,26.18,5.94,6.66,2.11,12.35,5.69,17.08,10.75,4.72,5.06,7.08,12.14,7.08,21.25,0,12.14-5.19,21.8-15.56,28.97-10.37,7.17-24.5,10.75-42.37,10.75-10.97,0-21.5-1.48-31.62-4.43Z"/>
                        <path fill='#103b7a' className="cls-1" d="M740.81,63.19c8.26-4.05,18.3-6.07,30.1-6.07v23.53c-1.35-.17-3.21-.25-5.57-.25-13.16,0-23.49,3.92-30.99,11.76-7.51,7.84-11.26,19.02-11.26,33.52v66.79h-24.29V58.38h23.28v22.52c4.21-7.76,10.46-13.66,18.72-17.71Z"/>
                        <path fill='#103b7a' className="cls-1" d="M821.38,185.13c-10.71-5.9-19.1-14.04-25.17-24.41-6.07-10.37-9.11-22.14-9.11-35.29s3.04-24.92,9.11-35.29c6.07-10.37,14.46-18.47,25.17-24.29,10.71-5.82,22.9-8.73,36.56-8.73,12.14,0,22.98,2.45,32.51,7.34,9.53,4.89,16.91,11.98,22.14,21.25l-18.47,11.89c-4.22-6.41-9.45-11.21-15.69-14.42-6.24-3.2-13.16-4.81-20.74-4.81-8.77,0-16.66,1.94-23.66,5.82-7,3.88-12.48,9.4-16.44,16.57-3.96,7.17-5.94,15.39-5.94,24.67s1.98,17.75,5.94,24.92c3.96,7.17,9.44,12.69,16.44,16.57,7,3.88,14.88,5.82,23.66,5.82,7.59,0,14.5-1.6,20.74-4.81,6.24-3.2,11.47-8.01,15.69-14.42l18.47,11.64c-5.23,9.28-12.61,16.4-22.14,21.38-9.53,4.98-20.36,7.46-32.51,7.46-13.66,0-25.85-2.95-36.56-8.85Z"/>
                        <path fill='#103b7a' className="cls-1" d="M1054.39,71.91c10.03,9.87,15.05,24.33,15.05,43.39v77.16h-24.29v-74.38c0-12.99-3.12-22.77-9.36-29.35-6.24-6.58-15.18-9.87-26.82-9.87-13.16,0-23.53,3.84-31.12,11.51-7.59,7.68-11.38,18.68-11.38,33.02v69.07h-24.29V4.75h24.29v72.61c5.06-6.41,11.68-11.39,19.86-14.93,8.18-3.54,17.33-5.31,27.45-5.31,17.03,0,30.57,4.93,40.6,14.8Z"/> 
                        <path fill='#103b7a' className="cls-1 " d="M651.57,139.81c-2.08,8.93-6.24,16.73-12.49,23.36-1.79,1.9-3.69,3.64-5.69,5.2.06-.06.12-.1.18-.15,1.41-1.21.66-2.04.31-3.1-2.07-6.33-5.73-11.58-10.92-15.76-1.67-1.35-3.18-1.93-5.08-1.08,4.14-3.16,7.06-7.64,8.64-13.34,2.77-9.99-.88-19.69-7.93-25.62-2.9-2.44-6.38-4.25-10.23-5.17-2.76-.76-5.57-1-8.41-.7h0c-3.08.32-6.18,1.26-9.29,2.82-15.56,7.87-18.29,28.58-5.63,40.48.13.13.19.33.29.49-1.11.09-1.91.76-2.75,1.37-6.42,4.71-10.57,10.97-12.65,18.63-10.38-8.36-16.05-19.26-17.85-32.39-1.12-8.2-.21-16.12,2.69-23.82,4.37-11.55,11.78-20.5,22.65-26.56,7.44-4.15,15.36-6.33,23.75-6.48,10.92-.46,20.93,2.78,30.24,9.17,6.82,4.69,12.06,10.69,15.8,18.02,5.6,11,7.23,22.51,4.39,34.62Z"/>
                        <path fill='#7fa5dd' className="cls-2" d="M633.56,168.21c-.06.05-.12.1-.18.15-.02,0-.03.02-.05.03-5.83,4.54-12.5,7.62-19.89,9.44-.54.12-1.09.23-1.64.32-.78.15-1.56.28-2.35.39-14.36,2.03-27.35-1.57-38.83-10.46-.29-.22-.49-.56-.72-.85,2.08-7.65,6.23-13.92,12.65-18.63.84-.61,1.65-1.28,2.75-1.37,6.3,5.71,14.24,7.24,21.33,5.92.05,0,.1-.02.15-.03.97-.18,1.93-.41,2.85-.71.12-.03.23-.06.35-.11,2.98-.93,5.62-2.28,7.89-4.02,1.89-.84,3.41-.27,5.08,1.08,5.19,4.18,8.85,9.43,10.92,15.76.35,1.06,1.1,1.89-.31,3.1Z"/>
                        <path fill='#7fa5dd' className="cls-2" d="M586.38,128.49c-.57-7.35,6.42-15.88,15.75-15.76,8.46.11,15.81,6.1,15.78,16.15-.03,9.27-7.24,15.53-15.9,15.53-9.84,0-16.22-8.88-15.62-15.91Z"/>
                        <path fill='#103b7a' className="cls-1" d="M655.94,75.45c10.3,9.64,16.77,21.55,20.11,35.1,10.58,42.94-16.5,85.26-59.93,93.48-18.97,3.59-36.88.12-53.42-9.87-1.88-1.13-2.82-1.09-4.4.5-26.68,26.8-53.45,53.51-80.15,80.28-5.05,5.06-10.72,7.32-17.73,4.75-1.89-.69-3.65-1.63-4.94-3.26.07-1.46,1.28-2.13,2.15-2.99,32.94-32.97,65.9-65.93,98.86-98.88.81-.81,1.75-1.49,2.64-2.24.29.16.58.32.88.48.49.54.93,1.15,1.48,1.63,15.56,13.62,33.54,18.58,53.79,14.52,31.45-6.3,52.92-37.92,47.38-69.45-2.26-12.84-8.01-23.89-16.99-33.3,2.65-4.32,6.6-7.4,10.26-10.76Z"/>
                        <path fill='#7fa5dd' className="cls-2" d="M559.13,172.32c-.88.74-1.82,1.43-2.64,2.24-32.97,32.95-65.92,65.91-98.86,98.88-.87.87-2.08,1.54-2.15,2.99-6.68-6.9-6.51-15.57.58-22.67,24.18-24.22,48.4-48.42,72.6-72.62,2.76-2.76,5.45-5.61,8.32-8.26,1.44-1.32,1.47-2.25.46-3.94-18.9-31.63-13.89-70.17,12.78-95.28,30.82-29.02,78.04-26.59,105.72,1.78-3.66,3.36-7.61,6.44-10.26,10.76-.18,0-.37-.02-.55-.04-11.62-11.77-25.77-17.59-42.23-17.82-40.16-.55-69.78,37.46-59.54,76.26,2.82,10.67,8.38,19.7,15.77,27.73Z"/>
                    </svg>
                    </div>
                    <hr className='h-[2px] bg-[#103b7a] mb-3' />
                    <ul className="space-y-2 font-medium flex-1">
                        {usersAndDashBaordShowOrHide && (
                            <>
                                <li className='my-3'>
                                    <Link to={"/dashboard"} className={`flex items-center p-2 rounded-lg group
                                        ${isActiveTab('dashboard') ? 'bg-[#68acd9]' : 'hover:bg-[#b9d9ee]'}`}>
                                        <MdDashboard className={`${isActiveTab('dashboard') ? 'text-white' : 'text-[#7fa5dd]'}`} size={25} />
                                        <span className={`ms-3 font-bold text-[#1F2937] ${isActiveTab('dashboard') ? 'text-white' : 'text-[#1F2937]'}`}>Dashboard</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"users"} className={`flex items-center p-2 rounded-lg group
                                        ${isActiveTab('users') ? 'bg-[#68acd9]' : 'hover:bg-[#b9d9ee]'}`}>
                                        <FaUsers className={` ${isActiveTab('users') ? 'text-white' : 'text-[#7fa5dd]'}`} size={25} />
                                        <span className={`ms-3 font-bold ${isActiveTab('users') ? 'text-white' : 'text-[#1F2937]'}`}>Users</span>
                                    </Link>
                                </li>
                                <hr className='h-[2px] bg-[#103b7a]' />
                            </>
                        )}
                        {teamsShowOrHide && (
                            <li>
                                <Link to={"teams"} className={`flex items-center p-2 rounded-lg group
                                    ${isActiveTab('teams') ? 'bg-[#68acd9]' : 'hover:bg-[#b9d9ee]'}`}>
                                    <MdGroupWork className={`${isActiveTab('teams') ? 'text-white' : 'text-[#7fa5dd]'}`} size={25} />
                                    <span className={`ms-3 font-bold ${isActiveTab('teams') ? 'text-white' : 'text-[#1F2937]'}`}>Teams</span>
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link to={"/contacts"} className={`flex items-center p-2 rounded-lg group
                                ${isActiveTab('contacts') ? 'bg-[#68acd9]' : 'hover:bg-[#b9d9ee]'}`}>
                                <AiOutlineContacts className={`${isActiveTab('contacts') ? 'text-white' : 'text-[#7fa5dd]'}`} size={25} />
                                <span className={`ms-3 font-bold ${isActiveTab('contacts') ? 'text-white' : 'text-[#1F2937]'}`}>Contacts</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/allDeals"} className={`flex items-center p-2 rounded-lg group
                                ${isActiveTab('allDeals') ? 'bg-[#68acd9]' : 'hover:bg-[#b9d9ee]'}`}>
                                <FaHandshake className={`${isActiveTab('allDeals') ? 'text-white' : 'text-[#7fa5dd]'}`} size={25} />
                                <span className={`ms-3 font-bold ${isActiveTab('allDeals') ? 'text-white' : 'text-[#1F2937]'}`}>Deals</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={"/monitoring"} className={`flex items-center p-2 rounded-lg group
                                ${isActiveTab('monitoring') ? 'bg-[#68acd9]' : 'hover:bg-[#b9d9ee]'}`}>
                                <FaRegEye className={`${isActiveTab('monitoring') ? 'text-white' : 'text-[#7fa5dd]'}`} size={25} />
                                <span className={`ms-3 font-bold ${isActiveTab('monitoring') ? 'text-white' : 'text-[#1F2937]'}`}>Monitoring</span>
                            </Link>
                        </li>
                        <hr className='h-[1px] bg-[#103b7a]' />
                        <li>
                            <button onClick={() => {
                                Swal.fire({
                                    title: "Do you want to log out of this account?",
                                    icon: "question",
                                    showCancelButton: "cancel",
                                    cancelButtonColor: '#FF6060',
                                    iconColor: "#FF6060",
                                    confirmButtonText: "Yes",
                                    preConfirm: function () {
                                        sessionStorage.removeItem('authToken');
                                        sessionStorage.removeItem('role');
                                        window.location = '/login';
                                    }
                                })
                            }} className="flex w-full text-left items-center p-2 rounded-lg hover:bg-[#b9d9ee] group">
                                <FiLogOut size={25} color='#68acd9' />
                                <span className="flex-1 ms-3 whitespace-nowrap font-bold text-[#1F2937]">Log out</span>
                            </button>
                        </li>
                    </ul>
                    <div className="mt-auto">
                        <p className='font-bold text-[14px]'>
                            Noticed a bug? <a className='text-red-400 underline' rel="noopener noreferrer" target='_blank' href={`https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent('info@axisorch.com')}`}>Let us know</a>
                        </p>
                    </div>
                </div>
            </aside>
            <div className="flex-1 p-4 sm:ml-60">
                {/* محتوى الصفحة هنا */}
            </div>
        </div>
    );
}
