import { Outlet } from "react-router-dom";
import SideBar from "../pages/sidebar";

export default function Home() {
    return (
        <div className="flex min-h-screen">
            <SideBar />
            <main className="flex-1 bg-white">
                <Outlet />
            </main>
        </div>
    );
}