import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import Note from "../components/note";
import { useRecoilState } from "recoil";
import { urlOfHost } from "../components/atoms";


export default function NotesOfDeal(){
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const [rowDeleted,setRowDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loading,setLoading] = useState(false);
    const [notes,setNotes] =useState([]);
    const params = useParams();
    const url=`${linkOfHost}/contacts-services/contacts/${params.contactId}/deals/${params.dealId}/notes/`;
    const getAllNotes = async () => {
        try {
            const res = await axios.get(url);
            setLoading(true);
            setNotes(res.data)
        } catch (err) {
            if(err.response){
                console.log(err.response.data)
            }else{
                Swal.fire({
                    title:`${err.message}`,
                    icon:"error"
                })
            }
        }
    };
    useEffect(() => {
        getAllNotes();
    }, [rowDeleted])
    return(
        <div>
            {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <div className="flex mt-4">
                <Link to={`/contactDetail/${params.contactId}/deals/dealDetail/${params.dealId}/notes/addnote`} className="px-4 text-white font-medium rounded-lg py-2 bg-blue-600 hover:bg-blue-700">
                    <button className="">
                        Add Note
                    </button>
                </Link>
            </div>
            <>
                {loading?(
                    <>
                    {notes.map((note)=>{
                        return(
                            <Note key={note.id} note={note} setIsLoading={setIsLoading} rowDeleted={rowDeleted} setRowDeleted={setRowDeleted} 
                            url={`${linkOfHost}/contacts-services/contacts/${params.contactId}/deals/${params.dealId}/notes/${note.id}/`}/>
                        )
                    
                    })}
                    </>
                ):(
                    <div className="fixed ml-8 mt-16">
                        <ClipLoader color="blue" size={80} />
                    </div>
                )}
                </>
        </div>
    )
}