import axios from "axios";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { urlOfHost } from "../components/atoms";
import { useRecoilState } from "recoil";

export default function EndDeal({setEndDeal,setChangeDetail,changeDetail}){
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const [total,setTotal] = useState ('0')
    const [nameErr,setNameErr] = useState('')
    const [priceErr,setPriceErr] = useState('')
    const [quantityErr,setQuantityErr] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const proName = useRef();
    const proPrice = useRef();
    const proQuantity = useRef();
    const params = useParams();
    const url = `${linkOfHost}/contacts-services/contacts/${params.contactId}/deals/${params.dealId}/end_deal/`;
    const handelEnd = () =>{
        setIsLoading(true);
        axios.post(url,{
            name:proName.current.value,
            unit_price:proPrice.current.value,
            quantity:proQuantity.current.value
        }).then((res)=>{
            setChangeDetail(!changeDetail)
            setEndDeal(false);
            Swal.fire({
                icon:"success",
                title:"The deal was completed successfully."
            })
        }).catch((err)=>{
            if(err.response){
                console.log(err.response)
                if(err.response){
                    Object.keys(err.response.data).forEach(key => {
                        if (key === "name") {
                            const nameInput = document.getElementById("productName");
                            nameInput.style.border = '1px solid red';
                            setNameErr(err.response.data.name[0]);
                        }
                        if (key === "unit_price") {
                            const priceInput = document.getElementById("productPrice");
                            priceInput.style.border = '1px solid red';
                            setPriceErr(err.response.data.unit_price[0]);
                        }
                        if (key === "quantity") {
                            const quantityInput = document.getElementById("Quantity");
                            quantityInput.style.border = '1px solid red';
                            setQuantityErr(err.response.data.quantity[0]);
                        }
                    });
                }
            }else{
                Swal.fire({
                    icon:"error",
                    title:`${err.message}`
                })
            }
        }).finally(()=>{
            setIsLoading(false)
        })
    }
    const handleInputChange = (e, setErrorState) => {
        e.target.style.border = '1px solid #3b82f6'; 
        setErrorState(''); 
    };
    return(
        <div className="fixed inset-0 z-50 flex p-0 m-0 items-center justify-center bg-black bg-opacity-50">
        <div className="p-6 w-[450px]">
            {isLoading && (
                <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <form
            method="POST"
            onSubmit={(e) => {
                e.preventDefault();
            }}
            className="bg-white px-8 pt-6 pb-8"
            >
            <h1 className="mb-5 font-bold leading-tight tracking-tight md:text-xl">
                End a Deal
            </h1>
            <div className="mb-4">
                <label
                className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
                htmlFor="product Name"
                >
                    Product Name
                </label>
                <input
                onChange={(e)=>{
                    handleInputChange(e,setNameErr);
                }}
                ref={proName}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                id="productName"
                type="text"
                placeholder="Enter a Product Name"
                />
                <p id="nameP" className="text-left text-[#CE4343] text-sm pl-1">{nameErr}</p>
            </div>
            <div className="mb-4">
                <label
                className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
                htmlFor="product Price"
                >
                    Product Price
                </label>
                <input
                onChange={(e)=>{
                    handleInputChange(e,setPriceErr);
                    setTotal(proPrice.current.value*proQuantity.current.value)
                }}
                ref={proPrice}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                id="productPrice"
                type="number"
                placeholder="Enter a Product Price"
                />
                <p id="priceP" className="text-left text-[#CE4343] text-sm pl-1">{priceErr}</p>
            </div>
            <div className="mb-4">
                <label
                className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
                htmlFor="Quantity"
                >
                    Quantity
                </label>
                <input
                onChange={(e)=>{
                    handleInputChange(e,setQuantityErr);
                    setTotal(proPrice.current.value*proQuantity.current.value)
                }}
                ref={proQuantity}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                id="Quantity"
                type="number"
                placeholder="Enter a Quantity"
                />
                <p id="quantityP" className="text-left text-[#CE4343] text-sm pl-1">{quantityErr}</p>
            </div>
            <div className="mb-4">
                <label
                className="block text-left text-gray-700 text-sm font-openSansBold mb-2"
                htmlFor="Quantity"
                >
                    Total : <span className="ml-1">{total}</span>
                </label>
            </div>
                <div className="flex items-center">
                <button onClick={handelEnd}
                    className="mr-4 bg-blue-500 hover:bg-blue-600 text-white font-openSansBold py-1 px-4 rounded focus:outline-none"
                    type="button"
                >
                    End
                </button>
                <button onClick={()=>{
                    setEndDeal(false)
                }}
                    className="bg-red-500 hover:bg-red-600 text-white font-openSansBold py-1 px-4 rounded focus:outline-none"
                    type="button"
                >
                    Cancel
                </button>
                </div>
    </form>
    </div>
</div>
    )
}