import { useEffect, useState } from "react";

import axios from "axios";
import { ClipLoader } from "react-spinners";
import PaginationOfContacts from "../components/paginationofcontacts";
import SourceFilter from "../components/sourcefilter";
import ExportAllData from "../components/exportAllData";
import MoreFilters from "../components/moreFiltersOfContacts";
import TROfDeals from "../components/trOfDeals";
import Export from "../components/export";
import StatusFilterOfDeals from "../components/statusOfDeal";
import DefaultContactOrdering from "../components/defaultContactOrdering";
import UpContactOrdering from "../components/upContactOrdering";
import DownContactOrdering from "../components/downContactOrdering";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import { useRecoilState } from "recoil";
import { urlOfHost } from "../components/atoms";

export default function DealsPage() {
    const [linkOfHost,] = useRecoilState(urlOfHost);


    const [apply,setApply] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);

    const [labelOfCityFilter,setLabelOfCityFilter] = useState ('City');
    const [valueOfCityFilter,setValueOfCityFilter] = useState('');

    const [labelOfSalesRepFilter,setLabelOfSalesRepFilter] = useState ('Sales Rep');
    const [valueOfSalesRepFilter,setValueOfSalesRepFilter] = useState ('');


    const [labelOfSourceFilter,setLabelOfSourceFilter] = useState ('Source');
    const [valueOfSourceFilter,setValueOfSourceFilter] = useState ('');

    const [labelOfStatusFilter,setLabelOfStatusFilter] = useState ('Status');
    const [valueOfStatusFilter,setValueOfStatusFilter] = useState ('');


    const [ser,setSer] = useState ('');

    const [rowDeleted,setRowDeleted] = useState(false);


    const [ordredMyContacBytNum,setOrderedContactsByNum] = useState('');
    const [ordredMyContactByName,setOrderedContactsByName] = useState('');
    const [ordredMyContactByCity,setOrderedContactsByCity] = useState('');
    const [ordredMyContactBySalesRep,setOrderedContactsBySalesRep] = useState('');


    const [upOrderingByNum,setUpOrderingByNum] = useState(false);
    const [downOrderingByNum,setDownOrderingByNum] = useState(false);
    const [defaultOrderingByNum,setDefaultOrderingByNum] = useState(true);


    const [upOrderingByName,setUpOrderingByName] = useState(false);
    const [downOrderingByName,setDownOrderingByName] = useState(false);
    const [defaultOrderingByName,setDefaultOrderingByName] = useState(true);


    const [upOrderingByCity,setUpOrderingByCity] = useState(false);
    const [downOrderingByCity,setDownOrderingByCity] = useState(false);
    const [defaultOrderingByCity,setDefaultOrderingByCity] = useState(true);

    
    const [upOrderingBySalesRep,setUpOrderingBySalesRep] = useState(false);
    const [downOrderingBySalesRep,setDownOrderingBySalesRep] = useState(false);
    const [defaultOrderingBySalesRep,setDefaultOrderingBySalesRep] = useState(true);


    const [itemsNumber, setCount] = useState(0);
    const [myOffSet, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [displayedCount, setDisplayedCount] = useState(0)
    
    const [isLoading,setIsLoading] =useState(false);
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const url = `${linkOfHost}/contacts-services/deals/`;
    const params = {
        offset: myOffSet,
        limit: 20,
        contact__source:valueOfSourceFilter,
        status:valueOfStatusFilter,
        search:ser,
        ordering: `${ordredMyContacBytNum},${ordredMyContactByName},${ordredMyContactByCity},${ordredMyContactBySalesRep}`,
        contact__city:valueOfCityFilter,
        contact__assign_to_id :valueOfSalesRepFilter
    };
    const getAllDeals = async () => {
        try {
            setLoading(false)
            const data = await axios.get(url, {
                params })
            .then((res) => {
                setContacts(res.data.results);
                setCount(res.data.count);
            });
            setLoading(true)
        } catch (err) {
            console.log(err);
        }
    };
    const handleSelectAll = (currentPage) => {
        const currentContacts = contacts;  // العناصر الحالية في الصفحة
        const isAllSelected = currentContacts.every(contact => selectedRows[currentPage]?.some(row => row.id === contact.id));
        if (isAllSelected) {
            // إلغاء تحديد جميع العناصر لهذه الصفحة
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: []
            }));
        } else {
            // تحديد جميع العناصر لهذه الصفحة
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: currentContacts
            }));
        }
    };
    

    const handleSelectRow = (contact, currentPage) => {
        const isSelected = selectedRows[currentPage]?.some(row => row.id === contact.id);
        if (isSelected) {
            // إلغاء التحديد
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: prevSelected[currentPage]?.filter(row => row.id !== contact.id) || []
            }));
        } else {
            // إضافة التحديد
            setSelectedRows(prevSelected => ({
                ...prevSelected,
                [currentPage]: [...(prevSelected[currentPage] || []), contact]
            }));
        }
    };
    const handleDelete = async () => {
        try {
            setIsLoading(true)
            const selectedDeals = Object.values(selectedRows).flat();
                if (selectedDeals.length === 0) return;
                    await Promise.all(
                    selectedDeals.map(deal =>
                        axios.delete(`${linkOfHost}/contacts-services/contacts/${deal.contact_id}/deals/${deal.id}/`)
                    )
                );
                setIsLoading(false)
                setSelectedRows({});
                Swal.fire({
                    title:"Deals deleted successfully",
                    icon:"success"
                    })
                setRowDeleted(!rowDeleted);
        } catch (error) {
            setIsLoading(false)
            console.error("Error deleting Deals:", error);
        }
    };
    useEffect(() => {
        getAllDeals();
    }, [myOffSet,apply,rowDeleted]);
    
    useEffect(() => {
        if(clearFilter===false){
            getAllDeals();
        }
    }, [clearFilter]);
    
    useEffect(() => {
        setDisplayedCount(0);
        if (itemsNumber > 0) {
          const duration = 1500; 
          const stepTime = Math.abs(Math.floor(duration / itemsNumber)); 
            const counter = setInterval(() => {
            setDisplayedCount((prevCount) => {
            if (prevCount < itemsNumber) {
                return prevCount + 1; 
            } else {
                clearInterval(counter); 
                return prevCount;
            }
            });
        }, stepTime);
    
          return () => clearInterval(counter); 
        }
    }, [itemsNumber]);
    return (
        <div className="mt-5 mr-3 ml-6 sm:ml-0">
            {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <div className="mb-3 flex flex-row justify-between">
                    <div className="flex flex-row">
                        <ExportAllData url={`${linkOfHost}/contacts-services/all-deals-data/`} setState={setIsLoading} />
                    </div>
                    {
                        Object.values(selectedRows).some(rows => rows.length > 0) ? (
                            <div className="flex flex-row gap-x-3">
                                <button onClick={handleDelete}className="bg-red-500 hover:bg-red-600 px-2 py-1 text-white rounded-lg">Delete Selected</button>
                                <Export pageName={'deals'} setIsLoading={setIsLoading} selectedRows={selectedRows} />
                            </div>
                        ) : null
                    }
                <Tooltip
                    className="z-30"
                anchorId={`tooltip`}       
                render={() => (
                    <div>
                    <p className="text-left text-[16px]">filters</p>
                    <p>{ser!==''?(`search : ${ser}`):('')}</p>
                    <p>{valueOfStatusFilter!==''?(`Status : ${valueOfStatusFilter}`):('')}</p>
                    <p>{valueOfSourceFilter!==''?(`Source : ${valueOfSourceFilter}`):(``)}</p>
                    <p>{valueOfCityFilter!==''?(`City : ${labelOfCityFilter}`):(``)}</p>
                    <p>{valueOfSalesRepFilter!==''?(`Sales Rep : ${labelOfSalesRepFilter}`):(``)}</p>
                    </div>
                )}
            />
            </div>
            <hr className="mt-2 mb-3" />
            <div className="flex flex-row items-center justify-between text-lg text-gray-900  bg-[#4ab6c5dd] rounded-md h-10 w-full px-6 pr-[0.15rem]">
                <div className="flex flex-row ">
                <SourceFilter setClearFilter={setClearFilter} setValueOfSourceFilter={setValueOfSourceFilter} setLabelOfSourceFilter={setLabelOfSourceFilter} labelOfSourceFilter={labelOfSourceFilter} />
                <StatusFilterOfDeals setClearFilter={setClearFilter} setValueOfStatusFilter={setValueOfStatusFilter} setLabelOfStatusFilter={setLabelOfStatusFilter} labelOfStatusFilter={labelOfStatusFilter} />
                <MoreFilters setClearFilter={setClearFilter} setLabelOfCityFilter={setLabelOfCityFilter} labelOfCityFilter={labelOfCityFilter} setValueOfCityFilter={setValueOfCityFilter}
                        setValueOfSalesRepFilter={setValueOfSalesRepFilter} setLabelOfSalesRepFilter={setLabelOfSalesRepFilter} labelOfSalesRepFilter={labelOfSalesRepFilter}/>
                </div>
                <div className=" flex justify-self-end">
            {
                clearFilter?(  <button onClick={(e)=>{
                    setClearFilter(false);

                    setLabelOfCityFilter('City');
                    setValueOfCityFilter('');

                    setLabelOfSalesRepFilter('sales_Rep');
                    setValueOfSalesRepFilter('');

                    setLabelOfSourceFilter('Source')
                    setValueOfSourceFilter('')


                    setLabelOfStatusFilter('Status')
                    setValueOfStatusFilter('')

                    setSer('');
                    document.getElementById('default-search').value='';


                }} className=" font-sm text-white mx-3">Clear Filters
                </button>):('')
            }
                <div className="relative flex items-center w-80">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input onChange={(e)=>{
                        setSer(e.target.value);
                        setClearFilter(true);
                    }} type="search" id="default-search" className="block focus:outline-none focus:border-none w-80 p-2 ps-10 text-sm text-gray-900  rounded-lg bg-gray-50 " placeholder="Search" required />
                    <button
                    id="tooltip"
                    onClick={() => setApply(!apply)}
                    className="absolute inset-y-0 my-[0.8px] mr-[0.5px] end-0 px-4 text-white text-[16px] rounded-lg bg-blue-400 hover:bg-blue-500"
                >
                    Apply
                </button>
                </div>
        </div>
            </div>
            <div className="flex flex-col mt-3">
                <div className="overflow-x-auto min-w-[1030px] pb-4">
                    <div className="min-w-full inline-block align-middle">
                        <div className="overflow-hidden border-none rounded-lg ">
                            <table className="table-auto rounded-xl">
                                <thead className="w-full">
                                    <tr className="bg-blue-100">
                                        <th className="">
                                            <div className="flex items-center px-5 ">
                                            <input
                                                    onChange={() => handleSelectAll(currentPage)} 
                                                    checked={contacts.length > 0 && contacts.every(deal => selectedRows[currentPage]?.some(row => row.id === deal.id))} 
                                                    type="checkbox" value="" className="w-5 h-5 appearance-none border border-gray-300 rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-center checked:border-indigo-500 checked:bg-indigo-100" />
                                            </div>
                                        </th>
                                        <th scope="col" className="w-[13%] text-left whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Deal Num
                                        {defaultOrderingByNum===true?(<DefaultContactOrdering  value={'number'} setUpOrdering={setUpOrderingByNum} setDownOrdering={setDownOrderingByNum} setDefaultOrdering={setDefaultOrderingByNum} setValue={setOrderedContactsByNum} />)
                                        :(upOrderingByNum===true?(<UpContactOrdering left={'left-[72px]'} top={'top-[28px]'} value={'-number'} setUpOrdering={setUpOrderingByNum} setDownOrdering={setDownOrderingByNum} setDefaultOrdering={setDefaultOrderingByNum} setValue={setOrderedContactsByNum}  />)
                                        :(<DownContactOrdering left={'left-[60px]'} top={'top-[28px]'} value={'id'} setUpOrdering={setUpOrderingByNum} setDownOrdering={setDownOrderingByNum} setDefaultOrdering={setDefaultOrderingByNum} setValue={setOrderedContactsByNum} />))}
                                        </th>
                                        <th scope="col" className="w-[15%] text-left py-5 whitespace-nowrap relative text-sm leading-6 font-semibold text-gray-900 capitalize"> Full Name
                                        {defaultOrderingByName===true?(<DefaultContactOrdering value={'contact__full_name_en'} setValue={setOrderedContactsByName} setDownOrdering={setDownOrderingByName} setDefaultOrdering={setDefaultOrderingByName} setUpOrdering={setUpOrderingByName} />)
                                        :(upOrderingByName===true?(<UpContactOrdering left={'left-[73px]'} top={'top-[28px]'} value={'-contact__full_name_en'} setValue={setOrderedContactsByName} setDownOrdering={setDownOrderingByName} setDefaultOrdering={setDefaultOrderingByName} setUpOrdering={setUpOrderingByName} />)
                                        :(<DownContactOrdering left={'left-[61px]'} top={'top-[28px]'} value={'contact__full_name_en'} setValue={setOrderedContactsByName} setDownOrdering={setDownOrderingByName} setDefaultOrdering={setDefaultOrderingByName} setUpOrdering={setUpOrderingByName} />))}
                                        </th>
                                        <th scope="col" className="w-[13%] text-left whitespace-nowrap relative text-sm  leading-6 font-semibold text-gray-900 capitalize"> City
                                        {defaultOrderingByCity===true?(<DefaultContactOrdering value={'contact__city'} setValue={setOrderedContactsByCity} setUpOrdering={setUpOrderingByCity} setDownOrdering={setDownOrderingByCity} setDefaultOrdering={setDefaultOrderingByCity} />)
                                        :(upOrderingByCity===true?(<UpContactOrdering left={'left-[33px]'} top={'top-[28px]'} value={'-contact__city'} setValue={setOrderedContactsByCity} setUpOrdering={setUpOrderingByCity} setDownOrdering={setDownOrderingByCity} setDefaultOrdering={setDefaultOrderingByCity} />)
                                        :(<DownContactOrdering left={'left-[21px]'} top={'top-[28px]'} value={'contact__city'} setValue={setOrderedContactsByCity} setUpOrdering={setUpOrderingByCity} setDownOrdering={setDownOrderingByCity} setDefaultOrdering={setDefaultOrderingByCity} />))}
                                        </th>
                                        <th scope="col" className="w-[16%] text-left relative whitespace-nowrap text-sm  leading-6 font-semibold text-gray-900 capitalize"> Sales_REP 
                                        {defaultOrderingBySalesRep===true?(<DefaultContactOrdering value={'contact__assign_to__full_name_en'} setValue={setOrderedContactsBySalesRep} setDownOrdering={setDownOrderingBySalesRep} setDefaultOrdering={setDefaultOrderingBySalesRep} setUpOrdering={setUpOrderingBySalesRep} />)
                                        :(upOrderingBySalesRep===true?(<UpContactOrdering left={'left-[70px]'} top={'top-[28px]'} value={'-contact__assign_to__full_name_en'} setValue={setOrderedContactsBySalesRep} setDownOrdering={setDownOrderingBySalesRep} setDefaultOrdering={setDefaultOrderingBySalesRep} setUpOrdering={setUpOrderingBySalesRep} />)
                                        :(<DownContactOrdering left={'left-[58px]'} top={'top-[28px]'} value={'contact__assign_to__full_name_en'} setValue={setOrderedContactsBySalesRep} setDownOrdering={setDownOrderingBySalesRep} setDefaultOrdering={setDefaultOrderingBySalesRep} setUpOrdering={setUpOrderingBySalesRep} />))}
                                        </th>
                                        <th scope="col" className="w-[17%] text-left whitespace-nowrap text-sm  leading-6 font-semibold text-gray-900 capitalize"> Source </th>
                                        <th scope="col" className=" w-[11%] text-left whitespace-nowrap text-sm leading-6 font-semibold text-gray-900 capitalize"> Status </th>
                                        <th scope="col" className=" bg-white text-left "><p className="inline-block rounded-md text-center text-[17px] ml-2 font-medium  text-white bg-[#4ab6c5dd] px-3 py-5 w-full">{displayedCount} Items</p> </th> 
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300">
                                    {loading ? (
                                        contacts.map((deal, i) => {
                                            let bg= '';
                                            if(i%2===0){
                                                bg='bg-white';
                                            }else{
                                                bg='bg-gray-100';
                                            }
                                            const currentPage = myOffSet / 20 + 1;  // احسب الصفحة الحالية بناءً على offset
                                            const isChecked = selectedRows[currentPage]?.some((row) => row.id === deal.id);  // التحقق مما إذا كان الصف محددًا
                                            return (
                                                <TROfDeals rowDeleted={rowDeleted} setRowDeleted={setRowDeleted} bgColor={bg}  key={deal.id} deal={deal} isChecked={isChecked} handleSelectRow={() => handleSelectRow(deal, currentPage)} setIsLoading={setIsLoading} />
                                            )
                                        })
                                    ) : (
                                        <div className="fixed inset-0 flex items-center justify-center">
                                            <ClipLoader color="blue" size={100} />
                                        </div>
                                    )}
                                </tbody>
                            </table>
                            <PaginationOfContacts currentPage={currentPage} setCurrentPage={setCurrentPage} itemsNumber={itemsNumber} setOffset={setOffset} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}