import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import Swal from 'sweetalert2';
import { urlOfHost } from './atoms';

const ExportAllData = (props) => {
  const [linkOfHost,] = useRecoilState(urlOfHost);
  const [data , setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleRole = (roles)=>{
      let res =roles.map(obj => {
      if (obj.role === 2) {
        return { ...obj, role: "Sales-REB" }; // نسخ الكائن مع تعديل القيمة
      }else if(obj.role===3){
        return{...obj,role:"Sales-Manger"}
      }else if (obj.role===1){
        return({...obj,role:"Customer-Servcies"})
      }else{
        return({...obj,role:"Admin"}); // إعادة الكائن كما هو إذا لم يطابق الشرط
      }
    });
    return res;
  }
  const getAllData = async () => {
    try {
        const data = await axios.get(props.url)
        .then((res) => {
            setData(res.data)
        });
    } catch (err) {
        if(err.response){
          console.log(err.response.data)
        }else{
          Swal.fire({
            title:`${err.message}`,
            icon:"error"
          })
        }
    }
};



  useEffect(() => {
    getAllData();
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

    return (
    <div className="relative " ref={dropdownRef}>
        <button
        id="dropdownAvatarNameButton"
        onClick={toggleDropdown}
        className="flex items-center text-sm pe-1 font-medium bg-[#37c] rounded-lg py-2 px-4 text-white md:me-0"
        type="button"
        >
        <span className="sr-only">Open user menu</span>
        <span className="font-bold">Export All Data</span>
        <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
        >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownAvatarName"
          className="absolute top-9 left-[-8px] z-10 bg-gray-50 divide-y divide-gray-100 rounded-lg shadow w-44"
        >
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
          <li className=' hover:bg-gray-100 cursor-pointer'
            onClick={async (e) => {
            props.setState(true);
            let localArray = [...data];
          if (props.data === "users") {
            localArray = handleRole(localArray);
          }
            setIsOpen(false);
            try {
                const response = await axios.post(
                    `${linkOfHost}/files/export/`,
                    {
                        data: localArray, 
                        format_type: "excel"
                    },
                    { responseType: 'blob' }
                );
                props.setState(false);
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute('download', "data.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (err) {
                props.setState(false);
                if (err.response) {
                    console.log(err.response.data);
                } else {
                    Swal.fire({
                        title: `${err.message}`,
                        icon: "error"
                    });
                }
            }
            }}>
                <button className="block px-4 py-2 text-gray-900">
                    Excel
                </button>
            </li>
          <li className=' hover:bg-gray-100 cursor-pointer'
            onClick={async (e) => {
            props.setState(true);
            let localArray = [...data];
          if (props.data === "users") {
            localArray = handleRole(localArray);
          }
            setIsOpen(false);
            try {
                const response = await axios.post(
                    `${linkOfHost}/files/export/`,
                    {
                        data: localArray, 
                        format_type: "pdf"
                    },
                    { responseType: 'blob' }
                );
                props.setState(false);
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute('download', "data.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (err) {
                props.setState(false);
                if (err.response) {
                    console.log(err.response.data);
                } else {
                    Swal.fire({
                        title: `${err.message}`,
                        icon: "error"
                    });
                }
            }
            }}>
                <button className="block px-4 py-2 text-gray-900">
                    PDF
                </button>
            </li>
          
            <li onClick={(e)=>{
              setIsOpen(false)
            }} className=' hover:bg-gray-100 cursor-pointer'>
              <button
                className="block px-4 py-2 text-gray-900 hover:bg-gray-100"
              >
                Print
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ExportAllData;
