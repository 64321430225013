import React from 'react';
import { useRecoilState } from 'recoil';
import { statusOfDeal } from './atoms';
import { ClipLoader } from 'react-spinners';
const TickIcon = () => (
  <svg  viewBox="0 0 512 512" width="16" height="16" className="object-contain">
    <path
      d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
      fill="white"
    />
  </svg>
);

const Present = () => (
  <div className="flex justify-center items-center">
    <ClipLoader color="white" size={20} />
  </div>

)

const TimeLine = ({ wizards }) => {
  const [statusOfPhases,setStatusOfPhases] = useRecoilState(statusOfDeal);
  return (
    <div className="flex">
      <div className="steps flex max-w-full space-x-6">
        {wizards.map((wizard, index,arr) => (
          <div key={index} className="step flex flex-col items-center relative">
            <div
              id={`tooltip-${index}`}
              className={`number w-8 z-10 h-8 rounded-full border-2 flex justify-center items-center text-gray-900 font-semibold mb-4 ${
                wizard.complete ? 'bg-blue-600 border-blue-600' : 'border-blue-600'
              }`}
            >
              {statusOfPhases==='opened'?(arr.length-1===index?(wizard.complete?(<Present />):(wizard.number)):(wizard.complete===true?(arr[index+1].complete===true?(<TickIcon />):(<Present/>)):(wizard.number))):
              (statusOfPhases==='rejected'?(arr.length-1===index?(wizard.complete?(<span className='text-white'>{wizard.number}</span>):(wizard.number)):(wizard.complete?(arr[index+1].complete?(<TickIcon />):(<span className='text-white'>{wizard.number}</span>)):(wizard.number))):
              (wizard.complete?(<TickIcon />):(wizard.number)))}
            </div>
            {/* <Tooltip
              anchorId={`tooltip-${index}`}       
              render={() => (
                <div>
                  <p>Start_Date : {wizard.startDate}</p>
                  <p>End_Date : {
                    statusOfPhases==='rejected'?( wizard.endDate===null?('Not completed'):(wizard.endDate)):(
                      wizard.endDate===null?('present'):(wizard.endDate)
                    )
                  }</p>
                </div>
              )}
            /> */}
            <div className="info text-center">
              <p className="title font-medium mb-1">
                {wizard.title}
              </p>
              <p className="text text-gray-800 text-xs text-left mb-3">
                Start_Date : {wizard.startDate?(wizard.startDate):('____________')}
              </p>
              <p className="text text-gray-800 text-xs  text-left">
                End_Date : {wizard.endDate?(wizard.endDate):('_____________')}
              </p>
            </div>
            {index !== wizards.length - 1 && (
                <div className="absolute z-1  w-[5.9rem] right-[-3.7rem] h-1 md:w-[7.3rem] md:right-[-4.4rem] bg-blue-600 top-4"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeLine;
