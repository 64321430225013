import axios from "axios";
import { Chart, ArcElement, Tooltip, Legend, PieController } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { urlOfHost } from "./atoms";

Chart.register(ArcElement, Tooltip, Legend, PieController);

export default function Doughnut() {
  const [linkOfHost,] = useRecoilState(urlOfHost);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  
  const [chartData, setChartData] = useState({ data: [], labels: [] });

  const url = `${linkOfHost}/statistics/phases-statistics/`;

  const getInfo = async () => {
    try {
      const response = await axios.get(url);
      const data = response.data;
      
      const labels = data.map(item => item.phase);   
      const counts = data.map(item => item.count);   
      if (counts.every(count => count === 0)) {
        counts.fill(1);
      }    
      
      // تحديث حالة البيانات
      setChartData({ data: counts, labels: labels });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInfo();
  }, []); 

  useEffect(() => {
    if (chartData.data.length > 0 && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy(); 
      }

      const total = chartData.data.reduce((acc, value) => acc + value, 0);
      const percentages = chartData.data.map(value => ((value / total) * 100).toFixed(1));

      const myChartRef = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(myChartRef, {
        type: 'doughnut',
        data: {
          labels: chartData.labels,
          datasets: [{
            data: chartData.data, 
            backgroundColor: [
              "#36A2EB",
              "#FFCE56", 
              "#4BC0C0", 
              "#FF9F40", 
              "#FF6384",
              "#9966FF"  
            ]
          }]
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'right',
              labels: {
                usePointStyle: true,
                boxWidth: 10,
                padding: 30,
                generateLabels: (chart) => {
                  const data = chart.data;
                  const labels = data.labels || [];
                  const datasets = data.datasets || [];
                  if (!datasets.length) {
                    return [];
                  }
                  return labels.map((label, i) => {
                    const meta = chart.getDatasetMeta(0);
                    const ds = datasets[0];
                    const arc = meta.data[i];
                    const value = ds.data[i];
                    const percentage = percentages[i];
                    return {
                      text: `${label}: ${percentage}%`,
                      fillStyle: ds.backgroundColor[i],
                      hidden: arc.hidden,
                      lineWidth: 1,
                      strokeStyle: '#fff',
                      index: i,
                    };
                  });
                }
              }
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  const title = context[0].label;
                  return title;
                },
                label: function (context) {
                  const dataset = context.dataset;
                  const dataIndex = context.dataIndex;
                  const value = dataset.data[dataIndex];
                  return `Deals : ${value} `;
                }
              },
              displayColors: false,
              backgroundColor: "rgba(0,0,0,0.7)",
              titleColor: "white",
              bodyColor: "white",
              borderWidth: 1,
              borderColor: "#ccc",
              padding: 10
            }
          }
        }
      });
    }
  }, [chartData]); 

  return (
    <div className="flex flex-col items-center">
      <div className="h-[450px] w-[450px]">
        <canvas className="h-full w-full" ref={chartRef} />
      </div>
    </div>
  );
}
